import React, { Component } from "react";
import moment from "moment";

import { CommonService } from "../../js_modules/CommonService";

export class SellToWebAdminDropdownComponent extends Component {
    constructor(props) {
        super(props);

        this.onItemClick = this.onItemClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    formatData(data) {
        let value = "";
        if (data) {
            switch (this.props.displayFormat) {
                case "monthYear":
                    value = (data === "Last 30 Days" ? data : CommonService.formatMonthYear(moment(data)));
                    break;

                case "Year":
                    if (CommonService.isDateValid(data)) {
                        value = CommonService.formatYear(moment(data));
                    }
                    else {
                        value = data;
                    }
                    break;

                default:
                    value = data;
                    break;
            }
        }
        else {
            switch (this.props.displayFormat) {
                case "Year":
                    value = "All";
                    break;

                default:
                    value = data;
                    break;
            }
        }

        return value;
    }

    onItemClick(event, item) {
        if (this.props.onItemClick) {
            this.props.onItemClick(this.props.id, item, this.props.additionalData);
        }
    }

    onKeyDown(event) {
        if (this.props.onKeyDown) {
            this.props.onKeyDown(event, this.props.id, this.props.additionalData);
        }
    }

    render() {
        let colSpan = this.props.colSpan ?? "col-auto";
        let labelSpan = this.props.labelSpan ?? "col-form-label";
        let controlSpan = this.props.controlSpan ?? "col-auto";
        if (this.props.className) {
            colSpan += " " + this.props.className;
        }
        if (this.props.labelClassName) {
            labelSpan += " " + this.props.labelClassName;
        }
        if (this.props.controlClassName) {
            controlSpan += " " + this.props.controlClassName;
        }

        let ddlElements = [];
        if (this.props.data) {
            this.props.data.forEach((item, index) => {
                ddlElements.push(<button key={"ddlItem" + index} className="btn btn-link dropdown-item admin-dropdown-item" onClick={(event) => this.onItemClick(event, item)}>
                    {
                        item?.colorBox
                            ? <div style={{ color: item.colorBox, backgroundColor: item.colorBox, display: "inline-block", lineHeight: "14px", marginRight: "10px", width: "20px", height: "14px" }}>&nbsp;</div>
                            : null
                    }
                    {
                        this.props.itemKey
                            ? this.formatData(item[this.props.itemKey])
                            : this.formatData(item)
                    }
                </button>);
            });
        }

        let txtStyle = {
            "cursor": "pointer",
            "zIndex": "100"
        };

        if (!this.props.disabled) {
            txtStyle.backgroundColor = "white";
        }

        return <div className={"admin-control " + colSpan}>
            <div className={"input-group " + this.props.className}>
                {
                    this.props.label
                        ? <label className={"admin-label " + labelSpan}>
                            {this.props.label}:
                            {
                                this.props.isRequired
                                    ? <span className="text-danger">*</span>
                                    : null
                            }
                        </label>
                        : null
                }
                <div className={controlSpan}>
                    <div className="dropdown admin-dropdown">
                        <div className="input-group" data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                            <input className={"form-control " + (this.props.inputCssName ?? "")} value={this.formatData(this.props.value)} style={txtStyle} disabled={this.props.disabled} readOnly />
                            <button className="btn btn-secondary dropdown-toggle" disabled={this.props.disabled} />
                        </div>
                        <div className="dropdown-menu sell-to-admin-dropdown-menu" hidden={this.props.disabled} onKeyDown={(event) => this.onKeyDown(event)}>
                            {ddlElements}
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}