import { Component } from "react";

import { CommonService } from "../js_modules/CommonService";

import { SellToWebAdminDropdownComponent } from "../common/selltowebdropdown/SellToWebAdminDropdownComponent";
import { SellToWebAdminInputComponent } from "../common/selltowebinput/SellToWebAdminInputComponent";
import { SellToWebColorPickerComponent } from "../common/selltowebcolorpicker/SellToWebColorPickerComponent";
import { SellToWebAdminRadioButtonComponent } from "../common/selltowebradiobutton/SellToWebAdminRadioButtonComponent";
import { SellToWebAdminModalDialogComponent } from "../common/selltowebmodaldialog/SellToWebAdminModalDialogComponent";

import { AdminRouteComponent } from "../admin/AdminRouteComponent";

import { TenantSiteAttributesQueryComponent } from "./TenantSiteAttributesQueryComponent";
import { TenantSiteImageComponent } from "./TenantSiteImageComponent";
import { TenantSiteHTMLComponent } from "./TenantSiteHTMLComponent";

export class TenantSiteAttributesComponent extends Component {
    static SiteAreaCookie = "SiteAreaCookie";
    static SiteFormFactorCookie = "SiteFormFactorCookie";
    static SiteAttributeCookie = "SiteAttributeCookie";

    formFactors = ['Default', 'Mobile'];
    constructor(props) {
        super(props);
        this.state = {
            tenants: null,
            selectedTenant: null,
            sites: null,
            selectedSite: null,
            areas: null,
            selectedArea: null,
            selectedFormFactor: "Default",
            names: null,
            selectedName: null,
            siteAttributes: null,
            areaFormFactorSiteAttributes: null,
            selectedSiteAttribute: null,
            newSelectedSiteAttribute: null,
            oldSiteAttribute: null,
            siteAttributeValue: "",
            isSiteAttributeValueChanged: false,
            showSaveSiteAttributePopup: false,
            isLoadingData: false
        }

        this.onSaveYesClick = this.onSaveYesClick.bind(this);
        this.onSaveNoClick = this.onSaveNoClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.onAreaClick = this.onAreaClick.bind(this);
        this.onFormFactorClick = this.onFormFactorClick.bind(this);
        this.onSiteAttributeClick = this.onSiteAttributeClick.bind(this);
        this.onGetClick = this.onGetClick.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onTenantClick = this.onTenantClick.bind(this);
        this.onSiteClick = this.onSiteClick.bind(this);

        props.onPageChanged({
            pageTitle: this.pageTitle
        });
    }

    pageTitle = "Site Attributes"

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/Tenant/SiteAttribute", pageType: "Consumer/Admin", isLoggedIn: "Yes", properties: { logon: this.props.logon } });
            CommonService.clientAzureStorageLog("TenantSiteAttributesComponent", "componentDidMount", null, null, null, null, this.props.applicationInsights);
            this.getTenants();
        }
        catch (error) {
            console.logError(error, "TenantSiteAttributesComponent", "componentDidMount");
        }
    }

    getTenants() {
        try {
            this.setState({ isLoadingData: true });
            this.props.applicationInsights.trackTrace({ message: "TenantSiteAttributesComponent/getTenants", properties: { logon: this.props.logon } });
            fetch("/TenantSite/GetTenants", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json; charset=utf-8;"
                },
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    let selectedTenant = null;
                    let cookieTenant = CommonService.getCookie(AdminRouteComponent.TenantCookie);
                    if (cookieTenant !== "") {
                        selectedTenant = parsedResponse.find(tenant => tenant.guid === cookieTenant);
                    }
                    if (!selectedTenant) {
                        selectedTenant = parsedResponse[0];
                    }

                    this.setState({
                        isLoadingData: false,
                        tenants: parsedResponse
                    }, this.onTenantClick(null, selectedTenant));
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TenantSite/GetTenants", ...parsedError, logon: this.props.logon } });
                                console.logError(parsedError, "TenantSiteAttributesComponent", "getTenants");
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "TenantSiteAttributesComponent", "getTenants");
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "TenantSiteAttributesComponent", "getTenants");
        }
    }

    onTenantClick(event, selectedTenant) {
        try {
            if (this.state.isSiteAttributeValueChanged) {
                this.setState({
                    newSelectedTenant: selectedTenant,
                    newSelectedSite: null,
                    newSelectedArea: null,
                    newSelectedFormFactor: null,
                    newSelectedSiteAttribute: null,
                    showSaveSiteAttributePopup: true
                });
            }
            else {
                CommonService.setCookie(AdminRouteComponent.TenantCookie, selectedTenant ? selectedTenant.guid : "", 30);
                this.setState({
                    newSelectedTenant: null,
                    newSelectedSite: null,
                    newSelectedArea: null,
                    newSelectedFormFactor: null,
                    newSelectedSiteAttribute: null,
                    selectedTenant: selectedTenant,
                }, this.getSites);
            }
        }
        catch (error) {
            console.logError(error, "TenantSiteAttributesComponent", "onTenantClick");
        }
    }

    getSites() {
        this.setState({ isLoadingData: true });
        let inputData = {
            tenantGuid: this.state.selectedTenant.guid,
        };

        this.props.applicationInsights.trackTrace({ message: "TenantSiteAttributesComponent/getSites", properties: { ...inputData, logon: this.props.logon } });
        fetch("/TenantSite/GetSites", {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8;"
            },
            body: JSON.stringify(inputData),
            credentials: "same-origin"
        })
            .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
            .then(parsedResponse => {
                if (!this.state.selectedSite) {
                    let cookieSite = CommonService.getCookie(AdminRouteComponent.SiteCookie);
                    let selectedSite = null;
                    if (cookieSite !== "") {
                        selectedSite = parsedResponse.find(site => site.guid === cookieSite);
                    }
                    if (!selectedSite) {
                        selectedSite = parsedResponse[0];
                    }

                    this.setState({
                        sites: parsedResponse,
                        isLoadingData: false,
                    }, this.onSiteClick(null, selectedSite));
                }
                else {
                    this.setState({
                        isLoadingData: false,
                        sites: parsedResponse,
                        selectedSite: parsedResponse[0]
                    }, this.onSiteClick(null, this.state.selectedSite));
                }
            })
            .catch(notOKResponse => {
                this.setState({
                    isLoadingData: false
                });
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TenantSite/GetSites", ...inputData, ...parsedError, logon: this.props.logon } });
                            console.logError(parsedError, "TenantSiteAttributesComponent", "getSites");
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.logError(jsonParseError, "TenantSiteAttributesComponent", "getSites");
                        });
                }
            });
    }

    onSiteClick(id, selectedSite) {
        try {
            if (this.state.isSiteAttributeValueChanged) {
                this.setState({
                    newSelectedSite: selectedSite,
                    newSelectedArea: null,
                    newSelectedFormFactor: null,
                    newSelectedSiteAttribute: null,
                    showSaveSiteAttributePopup: true
                });
            }
            else {
                CommonService.setCookie(AdminRouteComponent.SiteCookie, selectedSite ? selectedSite.guid : "", 30);
                this.setState({
                    newSelectedSite: null,
                    newSelectedArea: null,
                    newSelectedFormFactor: null,
                    newSelectedSiteAttribute: null,
                    selectedSite: selectedSite
                }, this.onGetClick);
            }
        }
        catch (error) {
            console.logError(error, "TenantSiteAttributesComponent", "onSiteClick");
        }
    }

    onTextChange(id, value) {
        try {
            let selectedSiteAttribute = this.state.selectedSiteAttribute;
            selectedSiteAttribute.value = value;
            this.setState({
                selectedSiteAttribute: selectedSiteAttribute,
                isSiteAttributeValueChanged: true
            });
        }
        catch (error) {
            console.logError(error, "TenantSiteAttributesComponent", "onTextChange", id);
        }
    }

    onAreaClick(id, selectedArea) {
        try {
            if (this.state.isSiteAttributeValueChanged) {
                this.setState({
                    newSelectedArea: selectedArea,
                    newSelectedFormFactor: null,
                    newSelectedSiteAttribute: null,
                    showSaveSiteAttributePopup: true
                });
            }
            else {
                CommonService.setCookie(TenantSiteAttributesComponent.SiteAreaCookie, selectedArea, 30);
                let siteFormFactorCookie = CommonService.getCookie(TenantSiteAttributesComponent.SiteFormFactorCookie);
                this.setState({
                    newSelectedArea: null,
                    newSelectedFormFactor: null,
                    newSelectedSiteAttribute: null,
                    selectedArea: selectedArea
                }, () => {
                    this.onFormFactorClick(null, siteFormFactorCookie ? siteFormFactorCookie : this.formFactors[0])
                });
            }
        }
        catch (error) {
            console.logError(error, "TenantSiteAttributesComponent", "onSiteAttributeClick");
        }
    }

    onFormFactorClick(id, selectedFormFactor) {
        try {
            if (this.state.isSiteAttributeValueChanged) {
                this.setState({
                    newSelectedFormFactor: selectedFormFactor,
                    newSelectedSiteAttribute: null,
                    showSaveSiteAttributePopup: true
                });
            }
            else {
                CommonService.setCookie(TenantSiteAttributesComponent.SiteFormFactorCookie, selectedFormFactor, 30);
                let areaFormFactorSiteAttributes = this.state.siteAttributes.filter(siteAttribute => siteAttribute.area === this.state.selectedArea && siteAttribute.formFactor === selectedFormFactor);
                let siteAttributeCookie = CommonService.getCookie(TenantSiteAttributesComponent.SiteAttributeCookie);
                let cookieSiteAttribute = areaFormFactorSiteAttributes.find(siteAttribute => siteAttribute.area === this.state.selectedArea && siteAttribute.formFactor === selectedFormFactor && siteAttribute.name === siteAttributeCookie);
                this.setState({
                    newSelectedFormFactor: null,
                    newSelectedSiteAttribute: null,
                    selectedFormFactor: selectedFormFactor,
                    areaFormFactorSiteAttributes: areaFormFactorSiteAttributes
                }, () => {
                    this.onSiteAttributeClick(null, cookieSiteAttribute ? cookieSiteAttribute : areaFormFactorSiteAttributes[0])
                });
            }
        }
        catch (error) {
            console.logError(error, "TenantSiteAttributesComponent", "onSiteAttributeClick");
        }
    }

    onSiteAttributeClick(id, selectedSiteAttribute) {
        try {
            if (this.state.isSiteAttributeValueChanged) {
                this.setState({
                    newSelectedSiteAttribute: selectedSiteAttribute,
                    showSaveSiteAttributePopup: true
                });
            }
            else {
                CommonService.setCookie(TenantSiteAttributesComponent.SiteAttributeCookie, selectedSiteAttribute?.name, 30);
                this.setState({
                    newSelectedSiteAttribute: null,
                    selectedSiteAttribute: selectedSiteAttribute,
                    oldSiteAttribute: JSON.stringify(selectedSiteAttribute)
                });
            }
        }
        catch (error) {
            console.logError(error, "TenantSiteAttributesComponent", "onSiteAttributeClick");
        }
    }

    onGetClick() {
        try {
            this.setState({
                areas: null,
                selectedArea: null,
                selectedFormFactor: "Default",
                names: null,
                selectedName: null,
                siteAttributes: null,
                areaFormFactorSiteAttributes: null,
                selectedSiteAttribute: null,
                oldSiteAttribute: null,
                newSiteAttribute: null,
                siteAttributeValue: "",
                isLoadingData: true
            });

            let inputData = {
                id: null,
                siteGuid: this.state.selectedSite.guid,
                siteName: this.state.selectedSite.siteName,
                tenantShortName: this.state.tenants.filter(tenant => tenant.guid === this.state.selectedSite.tenantGuid)[0].shortName,
            };
            this.props.applicationInsights.trackTrace({ message: "TenantSiteAttributesComponent/onGetClick", properties: { ...inputData, logon: this.props.logon } });
            fetch("/TenantSite/GetSiteAttributes", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData),
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    let areas = Array.from(new Set(parsedResponse.map((item) => item.area)));
                    let siteAreaCookie = CommonService.getCookie(TenantSiteAttributesComponent.SiteAreaCookie);
                    this.setState({
                        isLoadingData: false,
                        cardTitle: this.state.selectedTenant?.name + " - " + this.state.selectedSite?.siteName,
                        siteAttributes: parsedResponse,
                        areas: areas
                    }, () => {
                        this.onAreaClick(null, siteAreaCookie ? siteAreaCookie : areas[0]);
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TenantSite/GetSiteAttributes", ...inputData, ...parsedError, logon: this.props.logon } });
                                console.logError(parsedError, "TenantSiteAttributesComponent", "onGetClick");
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "TenantSiteAttributesComponent", "onGetClick");
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "TenantSiteAttributesComponent", "onGetClick");
        }
    }

    onSaveClick() {
        try {
            this.setState({
                isLoadingData: true
            });

            const scriptRegex = new RegExp(/<(?<scriptType>script|noscript?)(?<scriptAttributes>[\s\S]*?)>(?<scriptCode>[\s\S]*?)<\/(script|noscript)>/g)
            if (this.state.selectedSiteAttribute.type !== "JavaScript" && (scriptRegex.test(this.state.selectedSiteAttribute.value))) {
                this.props.onShowAlert("danger", { message: "Script not allowed in this Attribute" });
                this.setState({
                    isLoadingData: false
                });
                return;
            }

            let inputData = {
                tenantShortName: this.state.selectedTenant.shortName,
                siteGuid: this.state.selectedSite.guid,
                siteName: this.state.selectedSite.siteName,
                area: this.state.selectedSiteAttribute.area,
                formFactor: this.state.selectedSiteAttribute.formFactor,
                name: this.state.selectedSiteAttribute.name,
                type: this.state.selectedSiteAttribute.type,
                value: this.state.selectedSiteAttribute.value
            };
            this.props.applicationInsights.trackTrace({ message: "TenantSiteAttributesComponent/onSaveClick", properties: { ...inputData, logon: this.props.logon } });
            return fetch("/TenantSite/SaveSiteAttribute", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData),
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    let isSiteAttributeValueChanged = this.state.isSiteAttributeValueChanged;
                    this.setState({
                        isLoadingData: false,
                        isSiteAttributeValueChanged: false
                    }, () => {
                        if (isSiteAttributeValueChanged) {
                            this.executeSiteAttributeChange();
                        }

                        this.props.onShowAlert("success", { message: "Changes saved successfully!" }, 4000);
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TenantSite/SaveSiteAttribute", ...inputData, ...parsedError, logon: this.props.logon } });
                                console.logError(parsedError, "TenantSiteAttributesComponent", "onSaveClick");
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "TenantSiteAttributesComponent", "onSaveClick");
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "TenantSiteAttributesComponent", "onSaveClick");
        }
    }

    onSaveYesClick(event) {
        try {
            this.setState({
                showSaveSiteAttributePopup: false
            }, () => {
                this.onSaveClick();
            })
        }
        catch (error) {
            console.logError(error, "TenantSiteAttributesComponent", "onSaveYesClick");
        }
    }

    onSaveNoClick(event) {
        try {
            let selectedSiteAttribute = this.state.selectedSiteAttribute;
            let oldSiteAttribute = JSON.parse(this.state.oldSiteAttribute);
            selectedSiteAttribute.value = oldSiteAttribute.value
            this.setState({
                selectedSiteAttribute: selectedSiteAttribute,
                isSiteAttributeValueChanged: false,
                showSaveSiteAttributePopup: false
            }, this.executeSiteAttributeChange);
        }
        catch (error) {
            console.logError(error, "TenantSiteAttributesComponent", "onSaveNoClick");
        }
    }

    onCancelClick() {
        this.setState({
            showSaveSiteAttributePopup: false,
            newSelectedTenant: null,
            newSelectedSite: null,
            newSelectedArea: null,
            newSelectedFormFactor: null,
            newSelectedSiteAttribute: null,
        });
    }

    executeSiteAttributeChange() {
        if (this.state.newSelectedSiteAttribute) {
            this.onSiteAttributeClick(null, this.state.newSelectedSiteAttribute);
        }
        else if (this.state.newSelectedFormFactor) {
            this.onFormFactorClick(null, this.state.newSelectedFormFactor);
        }
        else if (this.state.newSelectedArea) {
            this.onAreaClick(null, this.state.newSelectedArea);
        }
        else if (this.state.newSelectedSite) {
            this.onSiteClick(null, this.state.newSelectedSite);
        }
        else if (this.state.newSelectedTenant) {
            this.onTenantClick(null, this.state.newSelectedTenant);
        }
    }

    render() {
        let component = <SellToWebAdminInputComponent id={this.state.selectedSiteAttribute?.name} label="Value" value={this.state.selectedSiteAttribute?.value} onChange={this.onTextChange} colSpan="col-12" labelSpan="col-2" controlSpan="col-8" />
        switch (this.state.selectedSiteAttribute?.type) {
            case "Image":
                component = <TenantSiteImageComponent selectedSiteAttribute={this.state.selectedSiteAttribute} onTextChange={this.onTextChange} />
                break;

            case "Html":
                component = <TenantSiteHTMLComponent selectedSiteAttribute={this.state.selectedSiteAttribute} onTextChange={this.onTextChange} />
                break;

            case "JavaScript":
                component = <TenantSiteHTMLComponent selectedSiteAttribute={this.state.selectedSiteAttribute} onTextChange={this.onTextChange} />
                break;

            case "Color":
                component = <SellToWebColorPickerComponent selectedSiteAttribute={this.state.selectedSiteAttribute} onTextChange={this.onTextChange} label="Value" colSpan="col-12" labelSpan="col-2" />
                break;

            default:
                break;
        }

        return <div className="admin-body admin-fill-content">
            <div className="d-flex flex-column admin-fill-content">
                <SellToWebAdminModalDialogComponent
                    title={"Save Site Attributes"}
                    showDialog={this.state.showSaveSiteAttributePopup}
                    className="admin-tenant-site-attribute-delete-dialog"
                    onCancelClick={this.onCancelClick}
                >
                    <div className="card">
                        <div className="card-block">
                            <p>Do you want to save changes to Attribute: <br /> {this.state.selectedArea} {this.state.selectedSiteAttribute?.name}?</p>
                            <div className="d-flex justify-content-between">
                                <button className="btn btn-primary me-3" onClick={(event) => this.onSaveYesClick(event)}>
                                    Yes<i className="fa fa-check ps-2"></i>
                                </button>
                                <button className="btn btn-primary me-3" onClick={(event) => this.onSaveNoClick(event)}>
                                    No<i className="fas fa-times ps-2"></i>
                                </button>
                                <button className="btn btn-primary" onClick={(event) => this.onCancelClick(event)}>
                                    Cancel<i className="fas fa-times ps-2"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </SellToWebAdminModalDialogComponent>
                <TenantSiteAttributesQueryComponent
                    tenants={this.state.tenants}
                    selectedTenant={this.state.selectedTenant}
                    sites={this.state.sites}
                    selectedSite={this.state.selectedSite}
                    onTenantClick={this.onTenantClick}
                    onSiteClick={this.onSiteClick}
                    onGetClick={this.onGetClick}
                    onSaveClick={this.onSaveClick}
                    isLoadingData={this.state.isLoadingData}
                    isGlobalAdmin={this.props.isGlobalAdmin}
                />
                <br />
                <div className="admin-flex-item admin-fill-content">
                    <div className="card admin-fill-content">
                        <div className="card-header">
                            {this.state.cardTitle}
                        </div>
                        <div className="card-block admin-flex-item">
                            <div className="row" style={{ "width": "100%" }}>
                                <SellToWebAdminDropdownComponent label="Area" data={this.state.areas} onItemClick={this.onAreaClick} value={this.state.selectedArea ?? "Select Area"} colSpan="col-4" controlSpan="col-8" className="align-items-center" />
                                <SellToWebAdminRadioButtonComponent label="Form Factor" data={this.formFactors} onChange={this.onFormFactorClick} value={this.state.selectedFormFactor} name="rdlSelectedFormFactor" colSpan="col-4 d-flex align-items-center" controlSpan="me-3" className="mx-2" />
                                {/* <SellToWebAdminDropdownComponent label="Form factor" data={this.state.formFactors} onItemClick={this.onFormFactorClick} value={this.state.selectedFormFactor ?? "Select Form Factor"} colSpan="col-4" labelSpan="col-2" controlSpan="col-8" /> */}
                                <SellToWebAdminDropdownComponent label="Name" data={this.state.areaFormFactorSiteAttributes} itemKey="name" onItemClick={this.onSiteAttributeClick} value={this.state.selectedSiteAttribute ? this.state.selectedSiteAttribute.name : "Select Attribute"} colSpan="col-4" controlSpan="col-8" className="align-items-center" />
                            </div>
                            <hr />
                            <div className="row" style={{ "width": "100%" }}>
                                <div className="col-6">
                                    {component}
                                </div>
                                <div className="col-6">
                                    {
                                        this.state.selectedSiteAttribute && this.state.selectedSiteAttribute.tip !== null
                                            ? <div className="card admin-tenant-site-attribute-tips-card">
                                                <div className="card-header">
                                                    <span className="fas fa-info-circle" />&nbsp;&nbsp;
                                                    {this.state.selectedSiteAttribute.formFactor} {this.state.selectedSiteAttribute.area} {this.state.selectedSiteAttribute.name} Tips
                                                </div>
                                                <div className="card-block admin-tenant-site-attribute-tips-card-block">
                                                    <span dangerouslySetInnerHTML={{ __html: this.state.selectedSiteAttribute.tip }} />
                                                </div>
                                            </div>
                                            : ""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}