import React, { Component } from "react";

import { CommonService } from "../js_modules/CommonService";

import { TenantSiteOfferCalculationQueryComponent } from "./TenantSiteOfferCalculationQueryComponent";
import { TenantSiteOfferKBBCalculationComponent } from "./TenantSiteOfferKBBCalculationComponent";
import { TenantSiteOfferMMRCalculationComponent } from "./TenantSiteOfferMMRCalculationComponent";

export class TenantSiteOfferCalculationComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            tenants: null,
            tableKBBData: [],
            tableKBBTitle: "",
            paintKBBTable: true,
            primaryTableMMRData: [],
            tableMMRData: [],
            tableMMRTitle: "",
            paintMMRTable: true,
            selectedRow: null,
            addingNewRecord: false,
            selectedSite: null,
            selectedTenant: null,
        };

        this.onGetClick = this.onGetClick.bind(this);
        this.onCancelAddClick = this.onCancelAddClick.bind(this);
        this.onAddNewCalculationSetupClick = this.onAddNewCalculationSetupClick.bind(this);
        this.onLookupClick = this.onLookupClick.bind(this);

        props.onPageChanged({
            pageTitle: this.pageTitle
        });
    }

    pageTitle = "Offer Calculation";

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/Tenant/OfferCalculation", pageType: "Consumer/Admin", isLoggedIn: "Yes", properties: { logon: this.props.logon } });
            CommonService.clientAzureStorageLog("TenantSiteOfferCalculationComponent", "componentDidMount", null, null, null, null, this.props.applicationInsights);
            this.getTenants();
            this.onLookupClick();
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferCalculationComponent", "componentDidMount");
        }
    }

    getTenants() {
        try {
            this.setState({ isLoadingData: true });
            this.props.applicationInsights.trackTrace({ message: "TenantSiteOfferCalculationComponent/getTenants", properties: { logon: this.props.logon } });
            fetch("/TenantSite/GetTenants", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json; charset=utf-8;"
                },
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        isLoadingData: false,
                        tenants: parsedResponse
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TenantSite/GetTenants", ...parsedError, logon: this.props.logon } });
                                console.logError(parsedError, "TenantSiteOfferCalculationComponent", "getTenants");
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "TenantSiteOfferCalculationComponent", "getTenants");
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferCalculationComponent", "getTenants");
        }
    }

    loadKBBOfferCalculation(selectedTenant, selectedSite) {
        let inputData = {
            siteGuid: selectedSite ? selectedSite.guid : this.state.selectedSite.guid
        };
        this.props.applicationInsights.trackTrace({ message: "TenantSiteOfferCalculationComponent/onGetClick", properties: { ...inputData, logon: this.props.logon } });
        fetch("/TenantSite/GetCalculationVariables", {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8;"
            },
            body: JSON.stringify(inputData),
            credentials: "same-origin"
        })
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                let defaultGeneralAdjustment;
                if (!(parsedResponse.table[0].make && parsedResponse.table[0].model && parsedResponse.table[0].year)) {
                    defaultGeneralAdjustment = parsedResponse.table[0].generalAdjustmentPct;
                }
                parsedResponse.table.forEach((rowData, index) => {
                    rowData.rowClassName = !rowData.enableOfferCalculation ? "app-sell-trade-offer-calculation-disabled" : "";
                    rowData.generalAdjustmentPctcellClassName = rowData.generalAdjustmentPct !== defaultGeneralAdjustment ? "app-sell-trade-offer-calculation-general-adjustment-yellow" : "";
                    rowData.accidentAdjustmentIsDollarLabel = "No";
                    rowData.accidentAdjustmentIsDollarLabel2 = "Yes";
                    rowData.generalAdjustmentIsDollarLabel = "No";
                    rowData.generalAdjustmentIsDollarLabel2 = "Yes";
                    rowData.EnableOfferLabel = "No";
                    rowData.EnableOfferLabel2 = "Yes";
                });

                this.setState({
                    tableKBBData: CommonService.addOrdinalColumn(parsedResponse.table),
                    tableKBBTitle: this.state.selectedTenant.name + " - " + this.state.selectedSite.siteName,
                    paintKBBTable: true,
                    isLoadingData: false
                }, this.setState({ paintTable: false }, this.onDisableSave));
            })
            .catch(notOKResponse => {
                this.setState({
                    isLoadingData: false
                });
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TenantSite/GetCalculationVariables", ...inputData, ...parsedError, logon: this.props.logon } });
                            console.logError(parsedError, "TenantSiteOfferCalculationComponent", "onGetClick");
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.logError(jsonParseError, "TenantSiteOfferCalculationComponent", "onGetClick");
                        });
                }
            });
    }

    loadMMROfferCalculation(selectedTenant, selectedSite) {
        let inputData = {
            siteGuid: selectedSite ? selectedSite.guid : this.state.selectedSite.guid
        };
        this.props.applicationInsights.trackTrace({ message: "TenantSiteOfferCalculationComponent/onGetClick", properties: { ...inputData, logon: this.props.logon } });
        let getFetchApiUrl = "/TenantSite/GetCalculationVariables";
        let valuationMethod = selectedSite ? selectedSite.valuationMethod : this.state.selectedSite.valuationMethod;
        if (valuationMethod === "MMR") {
            getFetchApiUrl = "/TenantSite/GetSiteCalculationConfigMMR";
        }
        fetch(getFetchApiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8;"
            },
            body: JSON.stringify(inputData),
            credentials: "same-origin"
        })
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                let defaultGeneralAdjustment;
                if (!(parsedResponse.table[0].make && parsedResponse.table[0].model && parsedResponse.table[0].year)) {
                    defaultGeneralAdjustment = parsedResponse.table[0].generalAdjustmentPct;
                }
                parsedResponse.table.forEach((rowData, index) => {
                    rowData.rowClassName = !rowData.enableOfferCalculation ? "app-sell-trade-offer-calculation-disabled" : "";
                    rowData.generalAdjustmentPctcellClassName = rowData.generalAdjustmentPct !== defaultGeneralAdjustment ? "app-sell-trade-offer-calculation-general-adjustment-yellow" : "";
                    rowData.accidentAdjustmentIsDollarLabel = "No";
                    rowData.accidentAdjustmentIsDollarLabel2 = "Yes";
                    rowData.generalAdjustmentIsDollarLabel = "No";
                    rowData.generalAdjustmentIsDollarLabel2 = "Yes";
                    rowData.EnableOfferLabel = "No";
                    rowData.EnableOfferLabel2 = "Yes";
                    if (rowData.accidentAdjustmentIsDollar) {
                        rowData.accidentAdjustmentValue_dataType = "money";
                    }
                    else {
                        rowData.accidentAdjustmentValue_dataType = "percent_1";
                    }
                    if (rowData.generalAdjustmentIsDollar) {
                        rowData.generalAdjustmentValue_dataType = "money";
                    }
                    else {
                        rowData.generalAdjustmentValue_dataType = "percent_1";
                    }
                });

                this.setState({
                    tableMMRData: CommonService.addOrdinalColumn(parsedResponse.table),
                    primaryTableMMRData: CommonService.addOrdinalColumn(parsedResponse.table),
                    tableMMRTitle: this.state.selectedTenant.name + " - " + this.state.selectedSite.siteName,
                    paintMMRTable: true,
                    isLoadingData: false
                }, this.setState({ paintTable: false }, this.onDisableSave));
            })
            .catch(notOKResponse => {
                this.setState({
                    isLoadingData: false
                });
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TenantSite/GetCalculationVariables", ...inputData, ...parsedError, logon: this.props.logon } });
                            console.logError(parsedError, "TenantSiteOfferCalculationComponent", "onGetClick");
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.logError(jsonParseError, "TenantSiteOfferCalculationComponent", "onGetClick");
                        });
                }
            });
    }

    onGetClick(selectedTenant, selectedSite) {
        try {
            this.setState({
                isLoadingData: true,
                selectedTenant: selectedTenant,
                selectedSite: selectedSite
            });

            if (selectedSite.valuationMethod === "KBB") {
                this.loadKBBOfferCalculation(selectedTenant, selectedSite);
            }
            else {
                this.loadMMROfferCalculation(selectedTenant, selectedSite);
            }
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferCalculationComponent", "onGetClick");
        }
    }

    onAddNewCalculationSetupClick() {
        try {
            this.setState({
                addingNewRecord: true
            });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferCalculationComponent", "onAddNewCalculationSetupClick");
        }
    }

    onCancelAddClick() {
        try {
            this.setState({
                addingNewRecord: false
            });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferCalculationComponent", "onCancelAddClick");
        }
    }

    onLookupClick(filterValue) {
        try {
            this.setState({
                isLoadingData: true,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            let tableData = this.state.primaryTableMMRData.filter(rowData => rowData.make?.search(new RegExp(filterValue, "i")) !== -1
                || (rowData.year ? rowData.year.search(new RegExp(filterValue, "i")) !== -1 : false)
                || (rowData.model ? rowData.model.search(new RegExp(filterValue, "i")) !== -1 : false));

            this.setState({
                isLoadingData: false,
                tableMMRData: tableData,
                paintTable: true,
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferCalculationComponent", "onLookupClick");
        }
    }


    render() {
        return (
            <div className="d-flex flex-column admin-fill-content admin-body">
                <TenantSiteOfferCalculationQueryComponent
                    tenants={this.state.tenants}
                    onAddClick={this.onAddNewCalculationSetupClick}
                    onGetClick={this.onGetClick}
                    onSiteClick={this.onSiteClick}
                    onTenantClick={this.onTenantClick}
                    isLoadingData={this.state.isLoadingData}
                    isGlobalAdmin={this.props.isGlobalAdmin}
                    applicationInsights={this.props.applicationInsights}
                    logon={this.state.logon}
                    onLookupClick={this.onLookupClick}
                />
                <br />
                {
                    this.state.selectedSite?.valuationMethod === "KBB"
                        ? <TenantSiteOfferKBBCalculationComponent
                            onShowAlert={this.props.onShowAlert}
                            tableTitle={this.state.tableKBBTitle}
                            tableData={this.state.tableKBBData}
                            paintTable={this.state.paintKBBTable}
                            selectedSite={this.state.selectedSite}
                            selectedTenant={this.state.selectedTenant}
                            applicationInsights={this.props.applicationInsights}
                            addingNewRecord={this.state.addingNewRecord}
                            onGetClick={this.onGetClick}
                            onCancelAddClick={this.onCancelAddClick}
                        />
                        : <TenantSiteOfferMMRCalculationComponent
                            onShowAlert={this.props.onShowAlert}
                            tableTitle={this.state.tableMMRTitle}
                            tableData={this.state.tableMMRData}
                            paintTable={this.state.paintMMRTable}
                            selectedSite={this.state.selectedSite}
                            selectedTenant={this.state.selectedTenant}
                            applicationInsights={this.props.applicationInsights}
                            addingNewRecord={this.state.addingNewRecord}
                            onGetClick={this.onGetClick}
                            onCancelAddClick={this.onCancelAddClick}
                            onLookupClick={this.onLookupClick}
                        />
                }
            </div>
        );
    }
}