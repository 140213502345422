import React, { Component } from "react";

import { CommonService } from "../js_modules/CommonService";

import { SellToWebAdminDropdownComponent } from "../common/selltowebdropdown/SellToWebAdminDropdownComponent";
import { SellToWebAdminInputComponent } from "../common/selltowebinput/SellToWebAdminInputComponent";
import { SellToWebModalDialogHeaderButtonComponent } from "../common/selltowebmodaldialog/SellToWebModalDialogHeaderButtonComponent";
import { SellToWebSpinnerComponent } from "../common/selltowebspinner/SellToWebSpinnerComponent";
import { SellToWebAdminToggleComponent } from "../common/selltowebcheckbox/SellToWebAdminToggleComponent";
import { SellToWebAdminModalDialogComponent } from "../common/selltowebmodaldialog/SellToWebAdminModalDialogComponent";

export class TenantSiteOfferMMRCalculationEditComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            maximumOfferEnabled: false,
            maximumOdometerEnabled: false,
            minimumYearEnabled: false,
            minimumGradeEnabled: false,
            likeNewEnabled: false,
            veryGoodEnabled: false,
            acceptableEnabled: false,
            majorIssuesEnabled: false,
            tireAdjustmentBelowThresholdEnabled: false,
            tireAdjustmentAboveThresholdEnabled: false,
            tireAdjustmentVehicleValueThresholdEnabled: false,
            windshieldAdjustmentBelowThresholdEnabled: false,
            windshieldAdjustmentAboveThresholdEnabled: false,
            windshieldAdjustmentVehicleValueThresholdEnabled: false,
            generalAdjustmentEnabled: false,
            accidentAdjustmentEnabled: false,
            maximumOffer: 0,
            maximumOdometer: 0,
            minimumYear: 0,
            minimumGrade: 0,
            likeNew: 0,
            veryGood: 0,
            acceptable: 0,
            majorIssues: 0,
            tireAdjustmentBelowThreshold: 0,
            tireAdjustmentAboveThreshold: 0,
            tireAdjustmentVehicleValueThreshold: 0,
            windshieldAdjustmentBelowThreshold: 0,
            windshieldAdjustmentAboveThreshold: 0,
            windshieldAdjustmentVehicleValueThreshold: 0,
            generalAdjustment: 0,
            accidentAdjustment: 0,
            generalAdjustmentIsDollar: false,
            accidentAdjustmentIsDollar: false,
            isLoadingData: false,
            saveDisabled: true,
            enableYMMEdit: false,
            makes: null,
            selectedMake: null,
            years: CommonService.getPreviousYearsByDate(25),
            selectedYear: null,
            models: null,
            selectedModel: null,
            enableOfferCalculation: true
        };

        this.onTextChange = this.onTextChange.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onSaveAsClick = this.onSaveAsClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onDisableSave = this.onDisableSave.bind(this);
        this.onYearClick = this.onYearClick.bind(this);
        this.onMakeClick = this.onMakeClick.bind(this);
        this.onModelClick = this.onModelClick.bind(this);
    }

    componentDidMount() {
        try {
            this.getVehicleMakes();
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferMMRCalculationEditComponent", "componentDidMount");
        }
    }


    componentDidUpdate(prevProps, prevState) {
        try {
            if (!prevProps.showDialog && this.props.showDialog) {
                if (this.props.isAddingRecord) {
                    this.setState({
                        selectedYear: null,
                        selectedMake: null,
                        models: null,
                        selectedModel: null
                    });
                }
                else if (this.props.rowData) {
                    if (this.props.rowData?.isDefault) {
                        let makesDefault = this.state.makes;
                        makesDefault.unshift({ makeName: "Default" });
                        let yearsDefault = this.state.years;
                        yearsDefault.unshift("Default");
                        let modelsDefault = [];
                        modelsDefault.unshift({ modelName: "Default" });
                        this.setState({
                            selectedYear: yearsDefault[0],
                            makes: makesDefault,
                            selectedMake: makesDefault[0],
                            models: modelsDefault,
                            selectedModel: modelsDefault[0]

                        }, () => {
                            this.fillForm(this.props.rowData);
                        });

                    }
                    else if ((this.props.rowData.year && !this.state.selectedYear) || (this.props.rowData.make && !this.state.selectedMake)) {
                        let selectedYear = this.props.rowData.year ? CommonService.formatYear(this.props.rowData.year.toString()) : null;
                        let selectedMake = (this.state.makes && this.props.rowData.make)
                            ? this.state.makes.filter(make => make.makeName.toString() === this.props.rowData.make.toString())[0]
                            : null;
                        this.setState({
                            selectedYear: selectedYear,
                            selectedMake: selectedMake,
                            models: null,
                            selectedModel: null
                        }, () => {
                            this.fillForm(this.props.rowData);
                            if (this.props.rowData.make) {
                                this.getVehicleModels();
                            }
                        });
                    }
                }
            }
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferMMRCalculationEditComponent", "componentDidUpdate");
        }
    }

    clearForm() {
        this.setState({
            selectedMake: null,
            selectedYear: null,
            models: null,
            selectedModel: null,
            maximumOffer: 0,
            maximumOdometer: 0,
            minimumYear: 0,
            minimumGrade: 0,
            likeNew: 0,
            veryGood: 0,
            acceptable: 0,
            majorIssues: 0,
            tireAdjustmentBelowThreshold: 0,
            tireAdjustmentAboveThreshold: 0,
            tireAdjustmentVehicleValueThreshold: 0,
            windshieldAdjustmentBelowThreshold: 0,
            windshieldAdjustmentAboveThreshold: 0,
            windshieldAdjustmentVehicleValueThreshold: 0,
            generalAdjustment: 0,
            accidentAdjustment: 0,
            enableOfferCalculation: true,
            saveDisabled: true,
            enableYMMEdit: false,
            isLoadingData: false,
            maximumOfferEnabled: false,
            maximumOdometerEnabled: false,
            minimumYearEnabled: false,
            minimumGradeEnabled: false,
            likeNewEnabled: false,
            veryGoodEnabled: false,
            acceptableEnabled: false,
            majorIssuesEnabled: false,
            tireAdjustmentBelowThresholdEnabled: false,
            tireAdjustmentAboveThresholdEnabled: false,
            tireAdjustmentVehicleValueThresholdEnabled: false,
            windshieldAdjustmentBelowThresholdEnabled: false,
            windshieldAdjustmentAboveThresholdEnabled: false,
            windshieldAdjustmentVehicleValueThresholdEnabled: false,
            generalAdjustmentIsDollar: false,
            accidentAdjustmentIsDollar: false
        }, this.onDisableSave);
    }

    fillForm(rowData) {
        this.setState({
            maximumOffer: CommonService.formatNumber_2(rowData.maximumOfferAmount),
            maximumOdometer: CommonService.formatNumber(rowData.maximumOdometer),
            minimumYear: rowData.minumumYear,
            minimumGrade: CommonService.formatNumber_1(rowData.minimumAverageGrade),
            likeNew: CommonService.formatNumber(rowData.gradeLikeNewConditionValue),
            veryGood: CommonService.formatNumber(rowData.gradeVeryGoodConditionValue),
            acceptable: CommonService.formatNumber(rowData.gradeAcceptableConditionValue),
            majorIssues: CommonService.formatNumber(rowData.gradeMajorIssuesConditionValue),
            tireAdjustmentBelowThreshold: CommonService.formatNumber_2(rowData.tireAdjustmentBelowThresholdAmount),
            tireAdjustmentAboveThreshold: CommonService.formatNumber_2(rowData.tireAdjustmentAboveThresholdAmount),
            tireAdjustmentVehicleValueThreshold: CommonService.formatNumber_2(rowData.tireAdjustmentThreshold),
            windshieldAdjustmentBelowThreshold: CommonService.formatNumber_2(rowData.windshieldAdjustmentBelowThresholdAmount),
            windshieldAdjustmentAboveThreshold: CommonService.formatNumber_2(rowData.windshieldAdjustmentAboveThresholdAmount),
            windshieldAdjustmentVehicleValueThreshold: CommonService.formatNumber_2(rowData.windshieldAdjustmentThreshold),
            accidentAdjustmentIsDollar: rowData.accidentAdjustmentIsDollar,
            accidentAdjustment: rowData.accidentAdjustmentIsDollar ? CommonService.formatNumber_2(rowData.accidentAdjustmentValue) : CommonService.formatNumber_1(rowData.accidentAdjustmentValue * 100),
            generalAdjustmentIsDollar: rowData.generalAdjustmentIsDollar,
            generalAdjustment: rowData.generalAdjustmentIsDollar ? CommonService.formatNumber_2(rowData.generalAdjustmentValue) : CommonService.formatNumber_1(rowData.generalAdjustmentValue * 100),
            enableOfferCalculation: rowData.enableOfferCalculation,
            maximumOfferEnabled: rowData.MaximumOfferAmountIsDefault === 0 ? true : false,
            maximumOdometerEnabled: rowData.MaximumOdometerIsDefault === 0 ? true : false,
            minimumYearEnabled: rowData.MinumumYearIsDefault === 0 ? true : false,
            minimumGradeEnabled: rowData.MinimumAverageGradeIsDefault === 0 ? true : false,
            likeNewEnabled: rowData.GradeLikeNewConditionValueIsDefault === 0 ? true : false,
            veryGoodEnabled: rowData.GradeVeryGoodConditionValueIsDefault === 0 ? true : false,
            acceptableEnabled: rowData.GradeAcceptableConditionValueIsDefault === 0 ? true : false,
            majorIssuesEnabled: rowData.GradeMajorIssuesConditionValueIsDefault === 0 ? true : false,
            tireAdjustmentBelowThresholdEnabled: rowData.TireAdjustmentBelowThresholdAmountIsDefault === 0 ? true : false,
            tireAdjustmentAboveThresholdEnabled: rowData.TireAdjustmentAboveThresholdAmountIsDefault === 0 ? true : false,
            tireAdjustmentVehicleValueThresholdEnabled: rowData.TireAdjustmentThresholdIsDefault === 0 ? true : false,
            windshieldAdjustmentBelowThresholdEnabled: rowData.WindshieldAdjustmentBelowThresholdAmountIsDefault === 0 ? true : false,
            windshieldAdjustmentAboveThresholdEnabled: rowData.WindshieldAdjustmentAboveThresholdAmountIsDefault === 0 ? true : false,
            windshieldAdjustmentVehicleValueThresholdEnabled: rowData.WindshieldAdjustmentThresholdIsDefault === 0 ? true : false,
            generalAdjustmentEnabled: rowData.AccidentAdjustmentValueIsDefault === 0 ? true : false,
            accidentAdjustmentEnabled: rowData.GeneralAdjustmentValueIsDefault === 0 ? true : false,
            isLoadingData: false
        }, this.onDisableSave);
    }

    getVehicleMakes() {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                year: this.state.selectedYear ? CommonService.formatYear(this.state.selectedYear) : ""
            };
            this.props.applicationInsights.trackTrace({ message: "TenantSiteOfferMMRCalculationEditComponent/getVehicleMakes", properties: { ...inputData, logon: this.props.logon } });
            fetch("/TenantSite/GetVehicleMakes", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData),
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        makes: parsedResponse.items,
                        selectedMake: null,
                        models: null,
                        selectedModel: null,
                        isLoadingData: false
                    });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TenantSite/GetVehicleMakes", ...inputData, ...parsedError, logon: this.props.logon } });
                                console.logError(parsedError, "TenantSiteOfferMMRCalculationEditComponent", "getVehicleMakes");
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "TenantSiteOfferMMRCalculationEditComponent", "getVehicleMakes");
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferMMRCalculationEditComponent", "getVehicleMakes");
        }
    }

    getVehicleModels() {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                year: this.state.selectedYear ? CommonService.formatYear(this.state.selectedYear) : "",
                makeId: this.state.selectedMake.makeId,
            };

            this.props.applicationInsights.trackTrace({ message: "TenantSiteOfferMMRCalculationEditComponent/getVehicleModels", properties: { ...inputData, logon: this.props.logon } });
            fetch("/TenantSite/GetVehicleModels", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData),
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let selectedModel = null;
                    if (this.props.rowData && this.props.rowData.model) {
                        selectedModel = parsedResponse.items.filter(model => model.modelName.toString() === this.props.rowData.model.toString())[0];
                    }
                    this.setState({
                        models: parsedResponse.items,
                        selectedModel: selectedModel,
                        isLoadingData: false
                    });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TenantSite/GetVehicleModels", ...inputData, ...parsedError, logon: this.props.logon } });
                                console.logError(parsedError, "TenantSiteOfferMMRCalculationEditComponent", "getVehicleModels");
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "TenantSiteOfferMMRCalculationEditComponent", "getVehicleModels");
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferMMRCalculationEditComponent", "getVehicleModels");
        }
    }

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value }, this.onDisableSave);
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferMMRCalculationEditComponent", "onTextChange", id);
        }
    }

    onCheckboxChange(id, value) {
        try {
            this.setState({ [id]: value }, this.onDisableSave);
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferMMRCalculationEditComponent", "onCheckboxChange", id);
        }
    }

    checkIfDefault() {
        return (this.state.selectedYear === "Default" && this.state.selectedMake?.makeName === "Default" && this.state.selectedModel?.modelName === "Default");
    }

    onSaveClick() {
        try {
            this.setState({
                isLoadingData: true,
                saveDisabled: true
            });
            let inputData = {
                siteGuid: this.props.rowData ? this.props.rowData.siteGuid : this.props.selectedSite.guid,
                isDefault: this.checkIfDefault(),
                year: this.state.selectedYear && this.state.selectedYear !== "Default" && this.state.selectedYear !== "All" ? parseInt(CommonService.formatYear(this.state.selectedYear)) : null,
                make: this.state.selectedMake && this.state.selectedMake.makeName !== "Default" ? this.state.selectedMake.makeName : null,
                model: this.state.selectedModel && this.state.selectedModel.modelName !== "Default" ? this.state.selectedModel.modelName : null,
                trim: null,
                enableOfferCalculation: this.state.enableOfferCalculation,
                maximumOfferAmount: !this.state.maximumOfferEnabled ? parseFloat(this.state.maximumOffer.replace(/,/g, '')) : null,
                maximumOdometer: !this.state.maximumOdometerEnabled ? parseInt(this.state.maximumOdometer.replace(/,/g, '')) : null,
                minumumYear: !this.state.minimumYearEnabled ? parseInt(this.state.minimumYear) : null,
                minimumAverageGrade: !this.state.minimumGradeEnabled ? parseFloat(this.state.minimumGrade.replace(/,/g, '')) : null,
                gradeLikeNewConditionValue: !this.state.likeNewEnabled ? parseFloat(this.state.likeNew.replace(/,/g, '')) : null,
                gradeVeryGoodConditionValue: !this.state.veryGoodEnabled ? parseFloat(this.state.veryGood.replace(/,/g, '')) : null,
                gradeAcceptableConditionValue: !this.state.acceptableEnabled ? parseFloat(this.state.acceptable.replace(/,/g, '')) : null,
                gradeMajorIssuesConditionValue: !this.state.majorIssuesEnabled ? parseFloat(this.state.majorIssues.replace(/,/g, '')) : null,
                tireAdjustmentBelowThresholdAmount: !this.state.tireAdjustmentBelowThresholdEnabled ? parseFloat(this.state.tireAdjustmentBelowThreshold.replace(/,/g, '')) : null,
                tireAdjustmentAboveThresholdAmount: !this.state.tireAdjustmentAboveThresholdEnabled ? parseFloat(this.state.tireAdjustmentAboveThreshold.replace(/,/g, '')) : null,
                tireAdjustmentThreshold: !this.state.tireAdjustmentVehicleValueThresholdEnabled ? parseFloat(this.state.tireAdjustmentVehicleValueThreshold.replace(/,/g, '')) : null,
                windshieldAdjustmentBelowThresholdAmount: !this.state.windshieldAdjustmentBelowThresholdEnabled ? parseFloat(this.state.windshieldAdjustmentBelowThreshold.replace(/,/g, '')) : null,
                windshieldAdjustmentAboveThresholdAmount: !this.state.windshieldAdjustmentAboveThresholdEnabled ? parseFloat(this.state.windshieldAdjustmentAboveThreshold.replace(/,/g, '')) : null,
                windshieldAdjustmentThreshold: !this.state.windshieldAdjustmentVehicleValueThresholdEnabled ? parseFloat(this.state.windshieldAdjustmentVehicleValueThreshold.replace(/,/g, '')) : null,
                accidentAdjustmentIsDollar: !this.state.accidentAdjustmentEnabled ? this.state.accidentAdjustmentIsDollar : null,
                accidentAdjustmentValue: !this.state.accidentAdjustmentEnabled ? (this.state.accidentAdjustmentIsDollar ? parseFloat(this.state.accidentAdjustment.replace(/,/g, '')) : (parseFloat(this.state.accidentAdjustment.replace(/,/g, '')) / 100)) : null,
                generalAdjustmentIsDollar: !this.state.generalAdjustmentEnabled ? this.state.generalAdjustmentIsDollar : null,
                generalAdjustmentValue: !this.state.generalAdjustmentEnabled ? (this.state.generalAdjustmentIsDollar ? parseFloat(this.state.generalAdjustment.replace(/,/g, '')) : (parseFloat(this.state.generalAdjustment.replace(/,/g, '')) / 100)) : null,
                isDelete: false,
                siteName: this.props.selectedSite.siteName,
                tenantShortName: this.props.selectedTenant.shortName
            };
            this.props.applicationInsights.trackTrace({ message: "TenantSiteOfferMMRCalculationEditComponent/onSaveClick", properties: { ...inputData, logon: this.props.logon } });
            fetch("/TenantSite/SaveSiteCalculationConfigMMR", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData),
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        isLoadingData: false
                    }, () => {
                        this.clearForm();
                        this.props.onSaveClick();
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TenantSite/SaveSiteCalculationVariable", ...inputData, ...parsedError, logon: this.props.logon } });
                                console.logError(parsedError, "TenantSiteOfferMMRCalculationEditComponent", "onSaveClick");
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "TenantSiteOfferMMRCalculationEditComponent", "onSaveClick");
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferMMRCalculationEditComponent", "onSaveClick");
        }
    }

    onSaveAsClick() {
        try {
            let removeMakesDefault = this.state.makes.filter(make => {
                return !(make.makeName === "Default");
            });
            let removeYearsDefault = this.state.years.filter(year => {
                return !(year === "Default");
            });
            let removeModelsDefault = this.state.models.filter(model => {
                return !(model.modelName === "Default");
            });
            this.setState({
                enableYMMEdit: true,
                makes: removeMakesDefault,
                years: removeYearsDefault,
                models: removeModelsDefault,
                selectedMake: null,
                selectedYear: null,
                selectedModel: null
            });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferMMRCalculationEditComponent", "onSaveAsClick");
        }
    }

    onCancelClick() {
        try {
            this.clearForm();
            this.props.onCancelClick();
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferMMRCalculationEditComponent", "onCancelClick");
        }
    }

    onDisableSave() {
        try {
            let disabled = (this.state.maximumOffer === null || this.state.maximumOffer === "")
                || (this.state.maximumOdometer === null || this.state.maximumOdometer === "")
                || (this.state.minimumYear === null || this.state.minimumYear === "")
                || (this.state.minimumGrade === null || this.state.minimumGrade === "")
                || (this.state.likeNew === null || this.state.likeNew === "")
                || (this.state.veryGood === null || this.state.veryGood === "")
                || (this.state.acceptable === null || this.state.acceptable === "")
                || (this.state.majorIssues === null || this.state.majorIssues === "")
                || (this.state.tireAdjustmentBelowThreshold === null || this.state.tireAdjustmentBelowThreshold === "")
                || (this.state.tireAdjustmentAboveThreshold === null || this.state.tireAdjustmentAboveThreshold === "")
                || (this.state.tireAdjustmentVehicleValueThreshold === null || this.state.tireAdjustmentVehicleValueThreshold === "")
                || (this.state.windshieldAdjustmentBelowThreshold === null || this.state.windshieldAdjustmentBelowThreshold === "")
                || (this.state.windshieldAdjustmentAboveThreshold === null || this.state.windshieldAdjustmentAboveThreshold === "")
                || (this.state.windshieldAdjustmentVehicleValueThreshold === null || this.state.windshieldAdjustmentVehicleValueThreshold === "")
                || (this.state.generalAdjustmentIsDollar === null || this.state.generalAdjustmentIsDollar === "")
                || (this.state.generalAdjustment === null || this.state.generalAdjustment === "")
                || (this.state.accidentAdjustmentIsDollar === null || this.state.accidentAdjustmentIsDollar === "")
                || (this.state.accidentAdjustment === null || this.state.accidentAdjustment === "");

            if (!disabled) {
                disabled = !(
                    (this.state.selectedYear && this.state.selectedMake && this.state.selectedModel)
                    || (this.state.selectedYear && this.state.selectedMake && !this.state.selectedModel)
                    || (!this.state.selectedYear && this.state.selectedMake && this.state.selectedModel)
                    || (!this.state.selectedYear && this.state.selectedMake && !this.state.selectedModel)
                    || (this.state.selectedYear && !this.state.selectedMake && !this.state.selectedModel)
                );
            }

            this.setState({ saveDisabled: disabled });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferMMRCalculationEditComponent", "onDisableSave");
        }
    }

    onYearClick(id, year) {
        try {
            this.setState({
                selectedYear: year,
                models: null,
                selectedModel: null
            }, () => {
                this.onDisableSave();
                if (this.state.selectedMake !== null) {
                    this.getVehicleModels();
                }
            });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferMMRCalculationEditComponent", "onYearClick", id);
        }
    }

    onMakeClick(id, make) {
        try {
            this.setState({
                selectedMake: make,
                models: null,
                selectedModel: null
            }, () => {
                this.onDisableSave();
                this.getVehicleModels();
            });

        }
        catch (error) {
            console.logError(error, "TenantSiteOfferMMRCalculationEditComponent", "onMakeClick", id);
        }
    }

    onModelClick(id, model) {
        try {
            this.setState({
                selectedModel: model
            }, () => {
                this.onDisableSave();
            });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferMMRCalculationEditComponent", "onModelClick", id);
        }
    }

    render() {
        let modalTitle = "MMR Site Configuration";
        if (this.props.rowData) {
            let extendedTitle = (this.state.selectedYear ? this.state.selectedYear : "")
                + (this.state.selectedMake && this.state.selectedMake.makeName ? " " + this.state.selectedMake.makeName : "")
                + (this.state.selectedModel && this.state.selectedModel.modelName ? " " + this.state.selectedModel.modelName : "");

            if (extendedTitle !== "")
                modalTitle += " - " + extendedTitle;
        }

        return <SellToWebAdminModalDialogComponent
            showDialog={this.props.showDialog}
            className="admin-site-configuration-dialog"
            title={modalTitle}
            onCancelClick={this.onCancelClick}
        >
            <SellToWebModalDialogHeaderButtonComponent>
                {
                    !this.props.isAddingRecord
                        ? <button className="btn btn-primary admin-icon " onClick={this.onSaveAsClick} disabled={this.state.enableYMMEdit}>
                            Save As <i className="fas fa-save admin-icon" />
                        </button>
                        : null
                }
                <button className="btn btn-primary admin-icon " onClick={this.onSaveClick} disabled={this.state.saveDisabled}>
                    Save <i className="fas fa-save admin-icon" />
                </button>
            </SellToWebModalDialogHeaderButtonComponent>
            <div className="admin-body">
                <div className="card">
                    <div className="card-block">
                        <div className="row">
                            <SellToWebAdminDropdownComponent label="Make" data={this.state.makes} disabled={!this.props.isAddingRecord && !this.state.enableYMMEdit} itemKey="makeName" onItemClick={this.onMakeClick} value={this.state.selectedMake ? this.state.selectedMake.makeName : ""} colSpan="col-4" />
                            <SellToWebAdminDropdownComponent label="Year" displayFormat="Year" id="years" data={this.state.years} disabled={!this.props.isAddingRecord && !this.state.enableYMMEdit} value={this.state.selectedYear} onItemClick={this.onYearClick} colSpan="col-4" />
                            <SellToWebAdminDropdownComponent label="Model" data={this.state.models} disabled={!this.props.isAddingRecord && !this.state.enableYMMEdit} itemKey="modelName" onItemClick={this.onModelClick} value={this.state.selectedModel ? this.state.selectedModel.modelName : ""} colSpan="col-4" inputCssName="admin-site-configuration-dialog-modal-input" />
                            <SellToWebSpinnerComponent showSpinner={this.state.isLoadingData} />
                        </div>
                        <div className="row">
                            <SellToWebAdminInputComponent id="maximumOffer" label="Maximum Offer" disabled={this.state.maximumOfferEnabled} value={this.state.maximumOfferEnabled ? this.props.defaultRow[0]?.maximumOfferAmount : this.state.maximumOffer} onChange={this.onTextChange} inputType="money" colSpan="col-8" labelSpan="col-7" controlSpan="col-5" />
                            <SellToWebAdminToggleComponent id="maximumOfferEnabled" label="Same as Default" disabled={this.checkIfDefault()} onChange={this.onCheckboxChange} checked={this.state.maximumOfferEnabled} colSpan="col-4" />
                        </div>
                        <div className="row">
                            <SellToWebAdminInputComponent id="maximumOdometer" label="Maximum Odometer" disabled={this.state.maximumOdometerEnabled} value={this.state.maximumOdometer} onChange={this.onTextChange} inputType="number" colSpan="col-8" labelSpan="col-7" controlSpan="col-5" />
                            <SellToWebAdminToggleComponent id="maximumOdometerEnabled" label="Same as Default" disabled={this.checkIfDefault()} onChange={this.onCheckboxChange} checked={this.state.maximumOdometerEnabled} colSpan="col-4" />
                        </div>
                        <div className="row">
                            <SellToWebAdminInputComponent id="minimumYear" label="Minimum Year" disabled={this.state.minimumYearEnabled} value={this.state.minimumYear} onChange={this.onTextChange} inputType="number" colSpan="col-8" labelSpan="col-7" controlSpan="col-5" />
                            <SellToWebAdminToggleComponent id="minimumYearEnabled" label="Same as Default" disabled={this.checkIfDefault()} onChange={this.onCheckboxChange} checked={this.state.minimumYearEnabled} colSpan="col-4" />
                        </div>
                        <div className="row">
                            <SellToWebAdminInputComponent id="minimumGrade" label="Minimum Average Grade" disabled={this.state.minimumGradeEnabled} value={this.state.minimumGrade} onChange={this.onTextChange} inputType="decimal" colSpan="col-8" labelSpan="col-7" controlSpan="col-5" />
                            <SellToWebAdminToggleComponent id="minimumGradeEnabled" label="Same as Default" disabled={this.checkIfDefault()} onChange={this.onCheckboxChange} checked={this.state.minimumGradeEnabled} colSpan="col-4" />
                        </div>
                        <div className="row">
                            <b className="admin-site-configuration-dialog-mmr-calculation-label">Condition Grade Value</b>
                        </div>
                        <div className="row">
                            <SellToWebAdminInputComponent id="likeNew" label="Like New" disabled={this.state.likeNewEnabled} value={this.state.likeNew} onChange={this.onTextChange} inputType="decimal" colSpan="col-8" labelSpan="col-7" controlSpan="col-5" labelClassName="admin-site-configuration-dialog-mmr-calculation-label" />
                            <SellToWebAdminToggleComponent id="likeNewEnabled" label="Same as Default" disabled={this.checkIfDefault()} onChange={this.onCheckboxChange} checked={this.state.likeNewEnabled} colSpan="col-4" />
                        </div>
                        <div className="row">
                            <SellToWebAdminInputComponent id="veryGood" label="Very Good" disabled={this.state.veryGoodEnabled} value={this.state.veryGood} onChange={this.onTextChange} inputType="decimal" colSpan="col-8" labelSpan="col-7" controlSpan="col-5" labelClassName="admin-site-configuration-dialog-mmr-calculation-label" />
                            <SellToWebAdminToggleComponent id="veryGoodEnabled" label="Same as Default" disabled={this.checkIfDefault()} onChange={this.onCheckboxChange} checked={this.state.veryGoodEnabled} colSpan="col-4" />
                        </div>
                        <div className="row">
                            <SellToWebAdminInputComponent id="acceptable" label="Acceptable" disabled={this.state.acceptableEnabled} value={this.state.acceptable} onChange={this.onTextChange} inputType="decimal" colSpan="col-8" labelSpan="col-7" controlSpan="col-5" labelClassName="admin-site-configuration-dialog-mmr-calculation-label" />
                            <SellToWebAdminToggleComponent id="acceptableEnabled" label="Same as Default" disabled={this.checkIfDefault()} onChange={this.onCheckboxChange} checked={this.state.acceptableEnabled} colSpan="col-4" />
                        </div>
                        <div className="row">
                            <SellToWebAdminInputComponent id="majorIssues" label="Major Issues" disabled={this.state.majorIssuesEnabled} value={this.state.majorIssues} onChange={this.onTextChange} inputType="decimal" colSpan="col-8" labelSpan="col-7" controlSpan="col-5" labelClassName="admin-site-configuration-dialog-mmr-calculation-label" />
                            <SellToWebAdminToggleComponent id="majorIssuesEnabled" label="Same as Default" disabled={this.checkIfDefault()} onChange={this.onCheckboxChange} checked={this.state.majorIssuesEnabled} colSpan="col-4" />
                        </div>
                        <div className="row">
                            <b className="admin-site-configuration-dialog-mmr-calculation-label">Tire Adjustment</b>
                        </div>
                        <div className="row">
                            <SellToWebAdminInputComponent id="tireAdjustmentVehicleValueThreshold" label="Threshold" disabled={this.state.tireAdjustmentVehicleValueThresholdEnabled} value={this.state.tireAdjustmentVehicleValueThreshold} onChange={this.onTextChange} inputType="money" colSpan="col-8" labelSpan="col-7" controlSpan="col-5" labelClassName="admin-site-configuration-dialog-mmr-calculation-label" />
                            <SellToWebAdminToggleComponent id="tireAdjustmentVehicleValueThresholdEnabled" label="Same as Default" disabled={this.checkIfDefault()} onChange={this.onCheckboxChange} checked={this.state.tireAdjustmentVehicleValueThresholdEnabled} colSpan="col-4" />
                        </div>
                        <div className="row">
                            <b className="admin-site-configuration-dialog-mmr-calculation-child-label">Adjustment when MMR Wholesale Avg</b>
                        </div>
                        <div className="row">
                            <SellToWebAdminInputComponent id="tireAdjustmentBelowThreshold" label="Below Tire Adjustment Threshold" disabled={this.state.tireAdjustmentBelowThresholdEnabled} value={this.state.tireAdjustmentBelowThreshold} onChange={this.onTextChange} inputType="money" colSpan="col-8" labelSpan="col-7" controlSpan="col-5" labelClassName="admin-site-configuration-dialog-mmr-calculation-child-label" />
                            <SellToWebAdminToggleComponent id="tireAdjustmentBelowThresholdEnabled" label="Same as Default" disabled={this.checkIfDefault()} onChange={this.onCheckboxChange} checked={this.state.tireAdjustmentBelowThresholdEnabled} colSpan="col-4" />
                        </div>
                        <div className="row">
                            <SellToWebAdminInputComponent id="tireAdjustmentAboveThreshold" label="Above Tire Adjustment Threshold" disabled={this.state.tireAdjustmentAboveThresholdEnabled} value={this.state.tireAdjustmentAboveThreshold} onChange={this.onTextChange} inputType="money" colSpan="col-8" labelSpan="col-7" controlSpan="col-5" labelClassName="admin-site-configuration-dialog-mmr-calculation-child-label" />
                            <SellToWebAdminToggleComponent id="tireAdjustmentAboveThresholdEnabled" label="Same as Default" disabled={this.checkIfDefault()} onChange={this.onCheckboxChange} checked={this.state.tireAdjustmentAboveThresholdEnabled} colSpan="col-4" />
                        </div>
                        <div className="row">
                            <b className="admin-site-configuration-dialog-mmr-calculation-label">Windshield Adjustment</b>
                        </div>
                        <div className="row">
                            <SellToWebAdminInputComponent id="windshieldAdjustmentVehicleValueThreshold" label="Threshold" disabled={this.state.windshieldAdjustmentVehicleValueThresholdEnabled} value={this.state.windshieldAdjustmentVehicleValueThreshold} onChange={this.onTextChange} inputType="money" colSpan="col-8" labelSpan="col-7" controlSpan="col-5" labelClassName="admin-site-configuration-dialog-mmr-calculation-label" />
                            <SellToWebAdminToggleComponent id="windshieldAdjustmentVehicleValueThresholdEnabled" label="Same as Default" disabled={this.checkIfDefault()} onChange={this.onCheckboxChange} checked={this.state.windshieldAdjustmentVehicleValueThresholdEnabled} colSpan="col-4" />
                        </div>
                        <div className="row">
                            <b className="admin-site-configuration-dialog-mmr-calculation-child-label">Adjustment when MMR Wholesale Avg</b>
                        </div>
                        <div className="row">
                            <SellToWebAdminInputComponent id="windshieldAdjustmentBelowThreshold" label="Below Windshield Adjustment Threshold" disabled={this.state.windshieldAdjustmentBelowThresholdEnabled} value={this.state.windshieldAdjustmentBelowThreshold} onChange={this.onTextChange} inputType="money" colSpan="col-8" labelSpan="col-7" controlSpan="col-5" labelClassName="admin-site-configuration-dialog-mmr-calculation-child-label" />
                            <SellToWebAdminToggleComponent id="windshieldAdjustmentBelowThresholdEnabled" label="Same as Default" disabled={this.checkIfDefault()} onChange={this.onCheckboxChange} checked={this.state.windshieldAdjustmentBelowThresholdEnabled} colSpan="col-4" />
                        </div>
                        <div className="row">
                            <SellToWebAdminInputComponent id="windshieldAdjustmentAboveThreshold" label="Above Windshield Adjustment Threshold" disabled={this.state.windshieldAdjustmentAboveThresholdEnabled} value={this.state.windshieldAdjustmentAboveThreshold} onChange={this.onTextChange} inputType="money" colSpan="col-8" labelSpan="col-7" controlSpan="col-5" labelClassName="admin-site-configuration-dialog-mmr-calculation-child-label" />
                            <SellToWebAdminToggleComponent id="windshieldAdjustmentAboveThresholdEnabled" label="Same as Default" disabled={this.checkIfDefault()} onChange={this.onCheckboxChange} checked={this.state.windshieldAdjustmentAboveThresholdEnabled} colSpan="col-4" />
                        </div>
                        <div className="row">
                            <div className="col-8">
                                <div className="row">
                                    <SellToWebAdminToggleComponent id="generalAdjustmentIsDollar" label="General Adjustment&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Percent" label2="Dollar" onChange={this.onCheckboxChange} value={this.state.generalAdjustmentIsDollar} colSpan="col-7" />
                                    <SellToWebAdminInputComponent id="generalAdjustment" disabled={this.state.generalAdjustmentEnabled} value={this.state.generalAdjustment} onChange={this.onTextChange} inputType={this.state.generalAdjustmentIsDollar ? "money" : "percent"} colSpan="col-5" controlSpan="col-12" />
                                </div>
                            </div>
                            <SellToWebAdminToggleComponent id="generalAdjustmentEnabled" label="Same as Default" disabled={this.checkIfDefault()} onChange={this.onCheckboxChange} checked={this.state.generalAdjustmentEnabled} colSpan="col-4" />
                        </div>
                        <div className="row">
                            <div className="col-8">
                                <div className="row">
                                    <SellToWebAdminToggleComponent id="accidentAdjustmentIsDollar" label="Accident Adjustment&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Percent" label2="Dollar" onChange={this.onCheckboxChange} value={this.state.accidentAdjustmentIsDollar} colSpan="col-7" />
                                    <SellToWebAdminInputComponent id="accidentAdjustment" disabled={this.state.accidentAdjustmentEnabled} value={this.state.accidentAdjustment} onChange={this.onTextChange} inputType={this.state.accidentAdjustmentIsDollar ? "money" : "percent"} colSpan="col-5" controlSpan="col-12" />
                                </div>
                            </div>
                            <SellToWebAdminToggleComponent id="accidentAdjustmentEnabled" label="Same as Default" disabled={this.checkIfDefault()} onChange={this.onCheckboxChange} checked={this.state.accidentAdjustmentEnabled} colSpan="col-4" />
                        </div>
                        <div className="row">
                            <div className="admin-control col-8">
                                <div className="input-group">
                                    <div className="col-7 admin-label">Enable Offer Calculation</div>
                                    <div className="col-5">
                                        <SellToWebAdminToggleComponent id="enableOfferCalculation" label="No" label2="Yes" disabled={this.state.enableYMMEdit} value={this.state.enableOfferCalculation} onChange={this.onCheckboxChange} colSpan="col-9" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SellToWebAdminModalDialogComponent>;
    }
}
