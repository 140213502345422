import { Component, Fragment } from "react";

import { SellToWebSpinnerComponent } from "../common/selltowebspinner/SellToWebSpinnerComponent";
import { SellToWebAdminDropdownComponent } from "../common/selltowebdropdown/SellToWebAdminDropdownComponent";

export class TenantSiteAttributesQueryComponent extends Component {
    constructor(props) {
        super(props);

        this.onTenantClick = this.onTenantClick.bind(this);
        this.onSiteClick = this.onSiteClick.bind(this);
        this.onGetClick = this.onGetClick.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
    }

    onTenantClick(event, selectedTenant) {
        try {
            this.props.onTenantClick(event, selectedTenant);
        }
        catch (error) {
            console.logError(error, "TenantSiteAttributesQueryComponent", "onTenantClick");
        }
    }

    onSiteClick(id, selectedSite) {
        try {
            this.props.onSiteClick(null, selectedSite);
        }
        catch (error) {
            console.logError(error, "TenantSiteAttributesQueryComponent", "onSiteClick");
        }
    }

    onGetClick(event) {
        try {
            this.props.onGetClick(this.props.selectedTenant, this.props.selectedSite);
        }
        catch (error) {
            console.logError(error, "TenantSiteAttributesQueryComponent", "onGetClick");
        }
    }

    onSaveClick() {
        try {
            this.props.onSaveClick(this.props.selectedTenant, this.props.selectedSite);
        }
        catch (error) {
            console.logError(error, "TenantSiteAttributesQueryComponent", "onSaveClick");
        }
    }

    render() {
        return <div className="card">
            <div className="card-block">
                <div className="row">
                    {
                        this.props.isGlobalAdmin
                            ? <SellToWebAdminDropdownComponent label="Tenant" data={this.props.tenants} itemKey="name" onItemClick={this.onTenantClick} value={this.props.selectedTenant ? this.props.selectedTenant.name : "Select Tenant"} controlSpan="admin-tenant-control"/>
                            : <Fragment>
                                <div className={"admin-control col-auto"}>
                                    <div className="input-group align-items-center">
                                        <label className={"admin-label col-form-label"}>
                                            Tenant:
                                        </label>
                                        {this.props.selectedTenant?.name}
                                    </div>
                                </div>
                            </Fragment>
                    }
                    <SellToWebAdminDropdownComponent label="Site" data={this.props.sites} itemKey="siteName" onItemClick={this.onSiteClick} value={this.props.selectedSite ? this.props.selectedSite.siteName : "Select Site"} />
                    <div className="col-auto admin-query-btn">
                        <button className="btn btn-primary" disabled={this.props.isLoadingData} onClick={(event) => this.onGetClick(event)}>
                            Get <i className="far fa-sync-alt admin-icon" />
                        </button>
                        <button className="btn btn-primary admin-icon" onClick={this.onSaveClick} disabled={this.props.isLoadingData}>
                            Save <i className="fas fa-save admin-icon" />
                        </button>
                        {
                            this.props.selectedSite
                                ? <a className="btn btn-primary admin-icon" target="_blank" rel="noreferrer" href={"https://" + this.props.selectedSite.siteDomain}>
                                    View Site
                                </a>
                                : null
                        }
                    </div>
                    <div style={{ "height": "33px", "width": "auto" }}>
                        <SellToWebSpinnerComponent className="admin-icon" showSpinner={this.props.isLoadingData} />
                    </div>
                </div>
            </div>
        </div>;
    }
}