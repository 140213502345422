import React, { Component, Fragment } from "react";
import { Navigate } from "react-router-dom";

import { CommonService } from "../js_modules/CommonService";

import { SellToWebTopPanelComponent } from "../common/selltowebtoppanel/SellToWebTopPanelComponent";
import { SellToWebRightPanelComponent } from "../common/selltowebrightpanel/SellToWebRightPanelComponent";
import { SellToWebDropdownComponent } from "../common/selltowebdropdown/SellToWebDropdownComponent";
import { SellToWebInputComponent } from "../common/selltowebinput/SellToWebInputComponent";
import { SellToWebButtonComponent } from "../common/selltowebbutton/SellToWebButtonComponent";

export class VehicleDetailsMMRRouteComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            trims: [{ trimName: "Select a Trim", disabled: true, selected: true }],
            selectedTrim: null,
            engineOptions: [{ optionName: "Select a Engine", disabled: true, selected: true }],
            selectedEngine: null,
            transmissionOptions: [{ optionName: "Select a Transmission", disabled: true, selected: true }],
            selectedTransmission: null,
            driveTrainOptions: [{ optionName: "Select a Drivetrain", disabled: true, selected: true }],
            selectedDriveTrain: null,
            exteriorColorOptions: [],
            selectedExteriorColor: null,
            kbbVehicleOEMColorOptions: [{ title: "Select a Paint Color", disabled: true, selected: true }],
            selectedKBBVehicleOEMColor: null,
            odometer: "",
            makePlusModel: "",
            imageUrl: "",
            isVehicleEquipmentClicked: false,
            showSpinner: false,
            redirect: false,
            differentColorShown: false,
            showNoImageAvailable: false
        };

        this.onOptionDropdownChange = this.onOptionDropdownChange.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.onOdometerChange = this.onOdometerChange.bind(this);
        this.onVehicleEquipmentClick = this.onVehicleEquipmentClick.bind(this);
        this.onMissingImage = this.onMissingImage.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    imageBaseUrl = "https://cdn.acquirecars.com/image/EVOX/";

    componentDidMount() {
        try {

            let homeData = CommonService.getSessionStorage("homeData");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/vehicle-details", pageType: "Consumer", isLoggedIn: "No", properties: { vin: homeData?.vin, vehicleId: homeData?.items[0]?.vehicleId, tenantName: this.props.tenantName, siteName: this.props.siteName } });
            if (homeData) {
                CommonService.clientAzureStorageLog("VehicleDetailsMMRRouteComponent", "componentDidMount", homeData?.items[0]?.vehicleId + " " + homeData?.vin, null, null, JSON.stringify(homeData), this.props.applicationInsights);

                let trimId = CommonService.getLocalStorage("Home" + homeData.vin + "SelectedTrimId");
                let trims = homeData.items;
                trims = JSON.parse(JSON.stringify(trims));
                let selectedTrim = null;
                let makePlusModel = trims[0].yearId + " " + trims[0].makeName + " " + trims[0].modelName;
                if (trims.length === 1) {
                    selectedTrim = trims[0];
                }
                else {
                    if (trimId) {
                        selectedTrim = trims.find(trim => trim.vehicleId === trimId);
                    }
                    else {
                        trims.unshift({ trimName: "Select a Trim", disabled: true, selected: true });
                    }
                }

                this.setState({ selectedTrim: selectedTrim, trims: trims, makePlusModel: makePlusModel }, () => {
                    if (selectedTrim) {
                        this.getOEMColors();
                    }
                });
            }
        }
        catch (error) {
            console.logError(error, "VehicleDetailsMMRRouteComponent", "componentDidMount");
        }
    }

    getOEMColors() {
        let homeData = CommonService.getSessionStorage("homeData");
        try {
            let inputData = {
                vehicleId: this.state.selectedTrim?.vehicleId.toString(),
                vin: homeData?.vin
            };
            this.props.applicationInsights.trackTrace({ message: "VehicleDetailsMMRRouteComponent/getOEMColors", properties: { ...inputData, tenantName: this.props.tenantName, siteName: this.props.siteName } });
            fetch("/VehicleDetails/GetKBBOemColors", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData),
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    if (parsedResponse && parsedResponse.length > 0) {
                        parsedResponse = [...parsedResponse].sort((a, b) => a.title > b.title ? 1 : -1);
                        parsedResponse.forEach((oemColor, index) => {
                            oemColor.colorBox = "#" + oemColor.rgb1;
                        });
                        let defaultColor = parsedResponse.find(x => x.simpletitle === "White");
                        if (!defaultColor) {
                            defaultColor = parsedResponse[0];
                        }

                        let otherColor = JSON.parse(JSON.stringify(defaultColor));
                        otherColor.title = "Other";
                        parsedResponse.push(otherColor);
                        this.setState({
                            kbbVehicleOEMColorOptions: parsedResponse
                        }, () => {
                            this.refreshVehicleOptions();
                        });
                    }
                    else {
                        let exteriorColorOptions = [];

                        this.state.selectedTrim?.vehicleOptions.forEach(vehicleOption => {
                            if (vehicleOption.categoryGroup === "Exterior Color") {
                                exteriorColorOptions.push(vehicleOption);
                            }
                            exteriorColorOptions.forEach((option, index) => {
                                option.colorBox = CommonService.checkCSSColorSupports(option.optionName);
                            });
                        });
                        this.setState({
                            kbbVehicleOEMColorOptions: exteriorColorOptions
                        }, () => {
                            this.refreshVehicleOptions();
                        });
                    }
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/VehicleDetails/GetKBBOemColors", ...inputData, ...parsedError, tenantName: this.props.tenantName, siteName: this.props.siteName } });
                                console.logError(parsedError, "VehicleDetailsMMRRouteComponent", "getOEMColors", this.state.selectedTrim?.vehicleId.toString() + " " + homeData?.vin);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "VehicleDetailsMMRRouteComponent", "getOEMColors", this.state.selectedTrim?.vehicleId.toString() + " " + homeData?.vin);
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "VehicleDetailsMMRRouteComponent", "getOEMColors", this.state.selectedTrim?.vehicleId.toString() + " " + homeData?.vin);
        }
    }

    refreshVehicleOptions() {
        let homeData = CommonService.getSessionStorage("homeData");
        try {
            let selectedTrim = this.state.selectedTrim;
            let trimId = selectedTrim.vehicleId;
            let imageUrl = this.imageBaseUrl + selectedTrim.vehicleId + ".front." + (this.state.selectedKBBVehicleOEMColor?.code ? this.state.selectedKBBVehicleOEMColor.code : "default") + ".png";
            let vehicleOptions = selectedTrim.vehicleOptions;
            let engineOptions = [], transmissionOptions = [], driveTrainOptions = [], exteriorColorOptions = [];
            let selectedEngine = null, selectedTransmission = null, selectedDriveTrain = null, selectedExteriorColor = null, selectedKBBVehicleOEMColor = null, odometer = "", differentColorShown = false, showNoImageAvailable = false;
            vehicleOptions.forEach((vehicleOption) => {
                switch (vehicleOption.categoryGroup) {
                    case "Engine":
                        engineOptions.push(vehicleOption);
                        if (vehicleOption.isTypical) {
                            selectedEngine = vehicleOption;
                        }
                        break;
                    case "Transmission":
                        transmissionOptions.push(vehicleOption);
                        if (vehicleOption.isTypical) {
                            selectedTransmission = vehicleOption;
                        }
                        break;
                    case "Drivetrain":
                        driveTrainOptions.push(vehicleOption);
                        if (vehicleOption.isTypical) {
                            selectedDriveTrain = vehicleOption;
                        }
                        break;
                    case "Exterior Color":
                        exteriorColorOptions.push(vehicleOption);
                        if (vehicleOption.isTypical) {
                            selectedExteriorColor = vehicleOption;
                        }
                        break;
                    default:
                        break;
                }
            });

            if (engineOptions.length > 1) {
                engineOptions.unshift({ optionName: "Select a Engine", disabled: true, selected: true });
            }
            selectedEngine = engineOptions[0];

            if (transmissionOptions.length > 1) {
                transmissionOptions.unshift({ optionName: "Select a Transmission", disabled: true, selected: true });
            }
            selectedTransmission = transmissionOptions[0];

            if (driveTrainOptions.length > 1) {
                driveTrainOptions.unshift({ optionName: "Select a Drivetrain", disabled: true, selected: true });
            }
            selectedDriveTrain = driveTrainOptions[0];


            let lsSelectedEngineId = CommonService.getLocalStorage("VehicleDetails" + trimId + "SelectedEngineId");
            if (lsSelectedEngineId) {
                selectedEngine = engineOptions.find(engine => engine.vehicleOptionId === lsSelectedEngineId);
            }

            let lsSelectedTransmissionId = CommonService.getLocalStorage("VehicleDetails" + trimId + "SelectedTransmissionId");
            if (lsSelectedTransmissionId) {
                selectedTransmission = transmissionOptions.find(transmission => transmission.vehicleOptionId === lsSelectedTransmissionId);
            }

            let lsSelectedDriveTrainId = CommonService.getLocalStorage("VehicleDetails" + trimId + "SelectedDriveTrainId");
            if (lsSelectedDriveTrainId) {
                selectedDriveTrain = driveTrainOptions.find(driveTrain => driveTrain.vehicleOptionId === lsSelectedDriveTrainId);
            }

            let lsSelectedKBBVehicleOEMColorCode = CommonService.getLocalStorage("VehicleDetails" + trimId + "SelectedKBBVehicleOEMColorCode");
            let lsSelectedKBBVehicleColorName = CommonService.getLocalStorage("VehicleDetails" + trimId + "SelectedKBBVehicleColorName");
            if (lsSelectedKBBVehicleOEMColorCode) {
                selectedKBBVehicleOEMColor = this.state.kbbVehicleOEMColorOptions.find(exteriorColor => exteriorColor.code === lsSelectedKBBVehicleOEMColorCode);
            }
            else {
                if (lsSelectedKBBVehicleColorName) {
                    selectedKBBVehicleOEMColor = exteriorColorOptions.find(exteriorColor => exteriorColor.optionName === lsSelectedKBBVehicleColorName);
                }
            }
            selectedKBBVehicleOEMColor = this.state.kbbVehicleOEMColorOptions.find(exteriorColor => exteriorColor.code === lsSelectedKBBVehicleOEMColorCode);
            let lsOdometer = CommonService.getLocalStorage("VehicleDetails" + trimId + "Odometer");
            if (lsOdometer) {
                odometer = lsOdometer;
            }

            let lsImageUrl = CommonService.getLocalStorage("VehicleDetails" + trimId + "ImageUrl");
            if (lsImageUrl) {
                imageUrl = lsImageUrl;
            }

            let lsDifferentColorShown = CommonService.getLocalStorage("VehicleDetails" + trimId + "DifferentColorShown");
            if (lsDifferentColorShown) {
                differentColorShown = lsDifferentColorShown;
            }

            let lsShowNoImageAvailable = CommonService.getLocalStorage("VehicleDetails" + trimId + "NoImageAvailable");
            if (lsShowNoImageAvailable) {
                showNoImageAvailable = lsShowNoImageAvailable;
            }
            selectedExteriorColor = exteriorColorOptions.find(eCP => {
                if (selectedKBBVehicleOEMColor && selectedKBBVehicleOEMColor.simpletitle) {
                    return eCP.optionName.toLowerCase() === selectedKBBVehicleOEMColor.simpletitle.toLowerCase();
                }
                return false;
            });
            if (selectedExteriorColor === undefined) {
                selectedExteriorColor = exteriorColorOptions.find(eCP => eCP.optionName === "White");
            }
            this.setState({
                selectedTrim: selectedTrim,
                engineOptions: engineOptions,
                selectedEngine: selectedEngine,
                transmissionOptions: transmissionOptions,
                selectedTransmission: selectedTransmission,
                driveTrainOptions: driveTrainOptions,
                selectedDriveTrain: selectedDriveTrain,
                exteriorColorOptions: exteriorColorOptions,
                selectedExteriorColor: selectedExteriorColor,
                selectedKBBVehicleOEMColor: selectedKBBVehicleOEMColor,
                odometer: odometer,
                imageUrl: imageUrl,
                differentColorShown: differentColorShown,
                showNoImageAvailable: showNoImageAvailable
            });
        }
        catch (error) {
            console.logError(error, "VehicleDetailsMMRRouteComponent", "refreshVehicleOptions", this.state.selectedTrim?.vehicleId.toString() + " " + homeData?.vin);
        }
    }

    updateSelectedVehicleColor(selectedKBBColor) {
        try {
            let imageUrl = this.imageBaseUrl + this.state.selectedTrim.vehicleId + ".front." + (this.state.selectedKBBVehicleOEMColor ? this.state.selectedKBBVehicleOEMColor.code : "default") + ".png";
            let exteriorColorOptions = this.state.exteriorColorOptions;
            if (exteriorColorOptions && selectedKBBColor.simpletitle) {
                let mappedExteriorColor = exteriorColorOptions.find(eCP => eCP.optionName.toLowerCase() === selectedKBBColor.simpletitle.toLowerCase());
                if (mappedExteriorColor) {
                    this.setState(
                        {
                            selectedExteriorColor: mappedExteriorColor,
                            imageUrl: imageUrl,
                            differentColorShown: false,
                            showNoImageAvailable: false
                        });
                }
            }
        }
        catch (error) {
            console.logError(error, "VehicleDetailsMMRRouteComponent", "updateSelectedVehicleColor", selectedKBBColor);
        }
    }

    updateTrimChange(id, selectedOption) {
        try {
            this.setState({
                [id]: this.state.trims.find(x => x.trimName === selectedOption.trimName),
                isVehicleEquipmentClicked: false,
                selectedKBBVehicleOEMColor: null
            }, () => {
                this.getOEMColors();
            });
        }
        catch (error) {
            console.logError(error, "VehicleDetailsMMRRouteComponent", "updateTrimChange", id);
        }
    }

    updateColorChange(id, selectedOption) {
        try {
            this.setState({ [id]: selectedOption, differentColorShown: false, showNoImageAvailable: false }, () => {
                this.updateSelectedVehicleColor(this.state.selectedKBBVehicleOEMColor !== null ? this.state.selectedKBBVehicleOEMColor : selectedOption);
            });
        }
        catch (error) {
            console.logError(error, "VehicleDetailsMMRRouteComponent", "updateColorChange", id);
        }
    }

    onOptionDropdownChange(id, selectedOption) {
        try {
            switch (id) {
                case "selectedTrim":
                    this.updateTrimChange(id, selectedOption);
                    break;
                case "selectedEngine":
                case "selectedTransmission":
                case "selectedDriveTrain":
                    this.setState({
                        [id]: selectedOption
                    });
                    break;
                case "selectedKBBVehicleOEMColor":
                    this.updateColorChange(id, selectedOption);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.logError(error, "VehicleDetailsMMRRouteComponent", "onOptionDropdownChange", id);
        }
    }

    onOdometerChange(id, value) {
        try {
            let formattedValue = value.replaceAll(",", "").replace(/[^0-9]/g, "");
            this.setState({
                [id]: formattedValue
            });
        }
        catch (error) {
            console.logError(error, "VehicleDetailsMMRRouteComponent", "onOdometerChange", id);
        }
    }

    handleKeyDown(event) {
        if (event.keyCode === 13) {
            event.preventDefault();
            this.onVehicleEquipmentClick();
        }
    }

    onMissingImage() {
        try {
            let colorCode = this.state.selectedKBBVehicleOEMColor?.code;
            let imageUrl = "";

            if (this.state.imageUrl.indexOf(".front." + colorCode) > -1) {
                imageUrl = this.imageBaseUrl + this.state.selectedTrim.vehicleId + ".side." + colorCode + ".png";
                this.setState({ imageUrl: imageUrl, differentColorShown: false, showNoImageAvailable: false });
            }
            else if (this.state.imageUrl.indexOf(".side." + colorCode) > -1) {
                imageUrl = this.imageBaseUrl + this.state.selectedTrim.vehicleId + ".front.default.png";
                this.setState({ imageUrl: imageUrl, differentColorShown: true, showNoImageAvailable: false });
            }
            else if (this.state.imageUrl.indexOf(".front.default.") > -1) {
                imageUrl = this.imageBaseUrl + this.state.selectedTrim.vehicleId + ".side.default.png";
                this.setState({ imageUrl: imageUrl, differentColorShown: true, showNoImageAvailable: false });
            }
            else if (this.state.imageUrl.indexOf(".side.default.") > -1) {
                this.setState({ imageUrl: "", differentColorShown: false, showNoImageAvailable: true });
            }
        }
        catch (error) {
            console.logError(error, "VehicleDetailsMMRRouteComponent", "onMissingImage");
        }
    }

    onBackButtonClick() {
        window.history.go(-1);
    }

    onVehicleEquipmentClick() {
        try {
            let inputsValid = this.state.selectedTrim?.trimName !== "Select a Trim" && this.state.selectedEngine?.optionName !== "Select a Engine"
                && this.state.selectedTransmission?.optionName !== "Select a Transmission" && this.state.selectedDriveTrain?.optionName !== "Select a Drivetrain"
                && this.state.selectedKBBVehicleOEMColor && this.state.selectedKBBVehicleOEMColor?.title !== "Select a Paint Color" && this.state.odometer;

            if (inputsValid) {
                let homeData = CommonService.getSessionStorage("homeData");
                let vehicleDetailsData = {
                    trims: this.state.trims,
                    selectedTrim: this.state.selectedTrim,
                    engineOptions: this.state.engineOptions,
                    selectedEngine: this.state.selectedEngine,
                    transmissionOptions: this.state.transmissionOptions,
                    selectedTransmission: this.state.selectedTransmission,
                    driveTrainOptions: this.state.driveTrainOptions,
                    selectedDriveTrain: this.state.selectedDriveTrain,
                    kbbVehicleOEMColorOptions: this.state.kbbVehicleOEMColorOptions,
                    selectedExteriorColor: this.state.selectedExteriorColor,
                    exteriorColorOptions: this.state.exteriorColorOptions,
                    selectedKBBVehicleOEMColor: this.state.selectedKBBVehicleOEMColor,
                    odometer: this.state.odometer,
                    vin: homeData?.vin,
                    licensePlate: homeData?.licensePlate === undefined ? "" : homeData?.licensePlate,
                    licensePlateState: homeData?.licensePlateState === undefined ? "" : homeData?.licensePlateState,
                    makePlusModel: this.state.makePlusModel,
                    imageUrl: this.state.imageUrl,
                    differentColorShown: this.state.differentColorShown
                };

                let trimId = this.state.selectedTrim.vehicleId;
                let offerSession = CommonService.getSessionStorage("offerSession");
                offerSession.vehicleDetail.year = this.state.selectedTrim.yearId;
                offerSession.vehicleDetail.make = this.state.selectedTrim.makeName;
                offerSession.vehicleDetail.makeId = this.state.selectedTrim.makeId;
                offerSession.vehicleDetail.model = this.state.selectedTrim.modelName;
                offerSession.vehicleDetail.modelId = this.state.selectedTrim.modelId;
                offerSession.vehicleDetail.trim = this.state.selectedTrim.trimName;
                offerSession.vehicleDetail.trimId = this.state.selectedTrim.trimId;
                offerSession.vehicleDetail.vehicleId = this.state.selectedTrim.vehicleId;
                offerSession.vehicleDetail.valuationZip = "";
                offerSession.vehicleDetail.odometer = this.state.odometer;
                offerSession.vehicleDetail.imageUrl = this.state.imageUrl;
                offerSession.vehicleDetail.colorCode = this.state.selectedKBBVehicleOEMColor.code;
                offerSession.vehicleDetail.colorTitle = this.state.selectedKBBVehicleOEMColor.title;
                offerSession.vehicleDetail.colorSimpleTitle = this.state.selectedKBBVehicleOEMColor.simpletitle ? this.state.selectedKBBVehicleOEMColor.simpletitle : this.state.selectedKBBVehicleOEMColor.optionName;
                offerSession.vehicleDetail.colorRgb1 = this.state.selectedKBBVehicleOEMColor.rgb1;
                offerSession.vehicleDetail.colorRgb2 = this.state.selectedKBBVehicleOEMColor.rgb2;
                CommonService.setSessionStorage("offerSession", offerSession);

                CommonService.setSessionStorage("vehicleDetailsData", vehicleDetailsData);
                CommonService.setLocalStorage("Home" + homeData.vin + "SelectedTrimId", trimId);
                CommonService.setLocalStorage("VehicleDetails" + trimId + "SelectedEngineId", this.state.selectedEngine.vehicleOptionId);
                CommonService.setLocalStorage("VehicleDetails" + trimId + "SelectedTransmissionId", this.state.selectedTransmission.vehicleOptionId);
                CommonService.setLocalStorage("VehicleDetails" + trimId + "SelectedDriveTrainId", this.state.selectedDriveTrain.vehicleOptionId);
                CommonService.setLocalStorage("VehicleDetails" + trimId + "SelectedKBBVehicleOEMColorCode", this.state.selectedKBBVehicleOEMColor.code ? this.state.selectedKBBVehicleOEMColor.code : "");
                CommonService.setLocalStorage("VehicleDetails" + trimId + "SelectedKBBVehicleColorName", this.state.selectedKBBVehicleOEMColor.optionName ? this.state.selectedKBBVehicleOEMColor.optionName : "");
                CommonService.setLocalStorage("VehicleDetails" + trimId + "Odometer", this.state.odometer);
                CommonService.setLocalStorage("VehicleDetails" + trimId + "ImageUrl", this.state.imageUrl);
                CommonService.setLocalStorage("VehicleDetails" + trimId + "DifferentColorShown", this.state.differentColorShown);
                CommonService.setLocalStorage("VehicleDetails" + trimId + "NoImageAvailable", this.state.showNoImageAvailable);
            }

            this.setState({
                redirect: inputsValid,
                isVehicleEquipmentClicked: true
            });
        }
        catch (error) {
            console.logError(error, "VehicleDetailsMMRRouteComponent", "onVehicleEquipmentClick", this.state.selectedTrim?.trimName);
        }
    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={"/vehicle-equipment"} />;
        }

        let homeData = CommonService.getSessionStorage("homeData");
        let bodyHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Vehicle Details", "Default", "Body Html")?.Value ?? "";
        return <Fragment>
            <SellToWebTopPanelComponent imageUrl={this.state.imageUrl} makePlusModel={this.state.makePlusModel} trim={this.state.selectedTrim && this.state.selectedTrim?.trimName !== "Select a Trim" ? this.state.selectedTrim?.trimName : "–"} />
            <section className="consumer-page">
                <section className="consumer-main">
                    <section className="ac-site-body ac-vehicledetails-body" dangerouslySetInnerHTML={{ __html: bodyHtml }} />
                    <section className="consumer-vehicledetails-form">
                        <SellToWebDropdownComponent id="selectedTrim" className="consumer-vehicledetails-form-trim" label="Trim" isChecked={(this.state.selectedTrim && this.state.selectedTrim?.trimName !== "Select a Trim")} data={this.state.trims} itemKey="trimName" value={this.state.selectedTrim !== null ? this.state.selectedTrim?.trimName : "Select a Trim"} onSelectChange={this.onOptionDropdownChange} isError={this.state.isVehicleEquipmentClicked && this.state.selectedTrim === null}></SellToWebDropdownComponent>
                        <SellToWebDropdownComponent id="selectedEngine" className={"consumer-vehicledetails-form-engine" + (!this.state.selectedTrim ? " consumer-vehicledetails-form-opacity" : "")} label="Engine" isChecked={(this.state.selectedEngine && this.state.selectedEngine?.optionName !== "Select a Engine")} disabled={!this.state.selectedTrim} data={this.state.engineOptions} itemKey="optionName" value={this.state.selectedEngine !== null ? this.state.selectedEngine?.optionName : "Select a Engine"} onSelectChange={this.onOptionDropdownChange} isError={this.state.isVehicleEquipmentClicked && (this.state.selectedEngine?.optionName === "Select a Engine" || this.state.selectedEngine === null)} ></SellToWebDropdownComponent>
                        <SellToWebDropdownComponent id="selectedTransmission" className={"consumer-vehicledetails-form-transmission" + (!this.state.selectedTrim ? " consumer-vehicledetails-form-opacity" : "")} label="Transmission" isChecked={(this.state.selectedTransmission && this.state.selectedTransmission?.optionName !== "Select a Transmission")} disabled={!this.state.selectedTrim} data={this.state.transmissionOptions} itemKey="optionName" value={this.state.selectedTransmission !== null ? this.state.selectedTransmission?.optionName : "Select a Transmission"} onSelectChange={this.onOptionDropdownChange} isError={this.state.isVehicleEquipmentClicked && (this.state.selectedTransmission?.optionName === "Select a Transmission" || this.state.selectedTransmission === null)} ></SellToWebDropdownComponent>
                        <SellToWebDropdownComponent id="selectedDriveTrain" className={"consumer-vehicledetails-form-drive-train" + (!this.state.selectedTrim ? " consumer-vehicledetails-form-opacity" : "")} label="Drivetrain" isChecked={(this.state.selectedDriveTrain && this.state.selectedDriveTrain?.optionName !== "Select a Drivetrain")} disabled={!this.state.selectedTrim} data={this.state.driveTrainOptions} itemKey="optionName" value={this.state.selectedDriveTrain !== null ? this.state.selectedDriveTrain?.optionName : "Select a Drivetrain"} onSelectChange={this.onOptionDropdownChange} isError={this.state.isVehicleEquipmentClicked && (this.state.selectedDriveTrain?.optionName === "Select a Drivetrain" || this.state.selectedTransmission === null)}></SellToWebDropdownComponent>
                        <SellToWebDropdownComponent id="selectedKBBVehicleOEMColor" className={"consumer-vehicledetails-form-oem" + (!this.state.selectedTrim ? " consumer-vehicledetails-form-opacity" : "")} classNameItem="app-fw-500" label="Exterior Color" isChecked={(this.state.selectedKBBVehicleOEMColor && this.state.selectedKBBVehicleOEMColor?.title !== "Select a Paint Color")} disabled={!this.state.selectedTrim} data={this.state.kbbVehicleOEMColorOptions} itemKey={this.state.kbbVehicleOEMColorOptions[0].title ? "title" : "optionName"} value={this.state.selectedKBBVehicleOEMColor !== null ? this.state.selectedKBBVehicleOEMColor?.title ? this.state.selectedKBBVehicleOEMColor?.title : this.state.selectedKBBVehicleOEMColor?.optionName : "Select a Paint Color"} onSelectChange={this.onOptionDropdownChange} isError={this.state.isVehicleEquipmentClicked && !this.state.selectedKBBVehicleOEMColor} colorBox={this.state.selectedKBBVehicleOEMColor?.colorBox ? CommonService.checkCSSColorSupports(this.state.selectedKBBVehicleOEMColor?.colorBox) : null}></SellToWebDropdownComponent>
                        <SellToWebInputComponent id="odometer" appendText="Miles" isChecked={this.state.odometer} className={!this.state.selectedTrim ? " consumer-vehicledetails-form-opacity" : ""} disabled={!this.state.selectedTrim} value={this.state.odometer ? CommonService.formatMoney(this.state.odometer) : ""} placeholder="Enter Your Current Mileage" onTextChange={this.onOdometerChange} onKeyDown={this.handleKeyDown} imagePath="images/miles-input.svg" isError={this.state.isVehicleEquipmentClicked && !this.state.odometer} />
                        <div className="d-flex justify-content-end">
                            <div style={{ "cursor": "pointer", "fontSize": "12px", "fontWeight": "400", "textDecoration": "underline", "margin": "auto 30px" }} onClick={(event => { window.history.go(-1); })}>
                                <span className="bi bi-chevron-left" style={{ "marginRight": "5px", "verticalAlign": "middle" }} />
                                Go Back
                            </div>
                            <SellToWebButtonComponent onClick={this.onVehicleEquipmentClick} showSpinner={this.state.showSpinner} value="Vehicle Equipment" />
                        </div>
                    </section>
                </section>
                <aside className="consumer-summary">
                    <SellToWebRightPanelComponent
                        vin={homeData?.vin} makePlusModel={this.state.makePlusModel}
                        imageUrl={this.state.imageUrl} differentColorShown={this.state.differentColorShown} showNoImageAvailable={this.state.showNoImageAvailable} odometer={CommonService.formatMoney(this.state.odometer)}
                        engine={this.state.selectedEngine && this.state.selectedEngine.optionName !== "Select a Engine" ? this.state.selectedEngine.optionName : "–"}
                        transmission={this.state.selectedTransmission && this.state.selectedTransmission.optionName !== "Select a Transmission" ? this.state.selectedTransmission.optionName : "–"}
                        driveTrain={this.state.selectedDriveTrain && this.state.selectedDriveTrain.optionName !== "Select a Drivetrain" ? this.state.selectedDriveTrain.optionName : "–"}
                        colorBox={this.state.selectedKBBVehicleOEMColor?.colorBox ? CommonService.checkCSSColorSupports(this.state.selectedKBBVehicleOEMColor?.colorBox) : null}
                        colorName={this.state.selectedKBBVehicleOEMColor?.title ? this.state.selectedKBBVehicleOEMColor.title : this.state.selectedKBBVehicleOEMColor?.optionName}
                        trim={this.state.selectedTrim && this.state.selectedTrim?.trimName !== "Select a Trim" ? this.state.selectedTrim?.trimName : "–"}
                        onMissingImage={this.onMissingImage} siteAttributes={this.props.siteAttributes} applicationInsights={this.state.applicationInsights}
                    />
                </aside>
            </section>
        </Fragment>;
    }
}