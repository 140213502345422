import React, { Component } from "react";

import { CommonService } from "../js_modules/CommonService";

import { SellToWebProgressbarComponent } from "../common/selltowebprogressbar/SelltoWebProgressbarComponent";

import { VehicleConditionKBBRouteComponent } from "./VehicleConditionKBBRouteComponent";
import { VehicleConditionMMRRouteComponent } from "./VehicleConditionMMRRouteComponent";
export class VehicleConditionRouteComponent extends Component {
    pageTitle = "Vehicle Condition";

    componentDidMount() {
        let vehicleDetailsData = CommonService.getSessionStorage("vehicleDetailsData");
        try {
            this.props.onPageLoad({ pageTitle: this.pageTitle });
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/vehicle-condition", pageType: "Consumer", isLoggedIn: "No", properties: { vin: vehicleDetailsData?.vin, vehicleId: vehicleDetailsData?.selectedTrim?.vehicleId, tenantName: this.props.tenantName, siteName: this.props.siteName } });
        }
        catch (error) {
            console.logError(error, "VehicleConditionRouteComponent", "componentDidMount", vehicleDetailsData?.selectedTrim?.vehicleId + " " + vehicleDetailsData?.vin);
        }
    }

    render() {
        let headerHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Vehicle Condition", "Default", "Header Html")?.Value;
        if (!headerHtml) {
            headerHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Site", "Default", "Header Html")?.Value ?? "";
        }

        let footerHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Vehicle Condition", "Default", "Footer Html")?.Value;
        if (!footerHtml) {
            footerHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Site", "Default", "Footer Html")?.Value ?? "";
        }
        return <div className="ac-site ac-vehiclecondition">
            <header className="ac-site-header ac-vehiclecondition-header" dangerouslySetInnerHTML={{ __html: headerHtml }} />
            <SellToWebProgressbarComponent progressIndex={4} />
            {
                this.props.valuationMethod === "Use KBB"
                    ? <VehicleConditionKBBRouteComponent
                        siteAttributes={this.props.siteAttributes}
                        applicationInsights={this.props.applicationInsights}
                    />
                    : <VehicleConditionMMRRouteComponent
                        siteAttributes={this.props.siteAttributes}
                        applicationInsights={this.props.applicationInsights}
                    />
            }
            <footer className="ac-site-footer ac-vehiclecondition-footer" dangerouslySetInnerHTML={{ __html: footerHtml }} />
        </div>;
    }
}