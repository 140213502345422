import React, { Component, Fragment } from "react";

import { CommonService } from "../js_modules/CommonService";

import { SellToWebAdminDropdownComponent } from "../common/selltowebdropdown/SellToWebAdminDropdownComponent";
import { SellToWebAdminInputComponent } from "../common/selltowebinput/SellToWebAdminInputComponent";
import { SellToWebSpinnerComponent } from "../common/selltowebspinner/SellToWebSpinnerComponent";
import { AdminRouteComponent } from "../admin/AdminRouteComponent";
export class TenantSiteOfferCalculationQueryComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTenant: null,
            sites: null,
            selectedSite: null,
            showSpinner: false,
            filterValue: null
        };

        this.onTenantClick = this.onTenantClick.bind(this);
        this.onSiteClick = this.onSiteClick.bind(this);
        this.onGetClick = this.onGetClick.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.onLookupClick = this.onLookupClick.bind(this);
    }

    componentDidUpdate() {
        try {
            if (this.props.tenants && !this.state.selectedTenant) {
                let selectedTenant = null;
                let cookieTenant = CommonService.getCookie(AdminRouteComponent.TenantCookie);
                if (cookieTenant !== "") {
                    selectedTenant = this.props.tenants.find(tenant => tenant.guid === cookieTenant);
                }
                if (!selectedTenant) {
                    selectedTenant = this.props.tenants[0];
                }

                this.setState({
                    selectedTenant: selectedTenant
                }, this.onTenantClick(null, selectedTenant));
            }
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferCalculationQueryComponent", "componentDidUpdate");
        }
    }

    getSites() {
        this.setState({ showSpinner: true });

        let inputData = {
            tenantGuid: this.state.selectedTenant.guid,
        };
        this.props.applicationInsights.trackTrace({ message: "TenantSiteOfferCalculationQueryComponent/getSites", properties: { ...inputData, logon: this.props.logon } });
        fetch("/TenantSite/GetSites", {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8;"
            },
            body: JSON.stringify(inputData),
            credentials: "same-origin"
        })
            .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
            .then(parsedResponse => {
                if (!this.state.selectedSite) {
                    let cookieSite = CommonService.getCookie(AdminRouteComponent.SiteCookie);
                    let selectedSite = null;
                    if (cookieSite !== "") {
                        selectedSite = parsedResponse.find(site => site.guid === cookieSite);
                    }
                    if (!selectedSite) {
                        selectedSite = parsedResponse[0];
                    }

                    this.setState({
                        sites: parsedResponse,
                        selectedSite: selectedSite,
                        showSpinner: false,
                    }, this.onSiteClick(null, selectedSite));
                }
                else {
                    this.setState({
                        showSpinner: false,
                        sites: parsedResponse,
                        selectedSite: parsedResponse[0]
                    }, this.onSiteClick(null, this.state.selectedSite));
                }
            })
            .catch(notOKResponse => {
                this.setState({
                    showSpinner: false
                });
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TenantSite/GetSites", ...inputData, ...parsedError, logon: this.props.logon } });
                            console.logError(parsedError, "TenantSiteOfferCalculationQueryComponent", "getSites");
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.logError(jsonParseError, "TenantSiteOfferCalculationQueryComponent", "getSites");
                        });
                }
            });
    }

    onTenantClick(event, selectedTenant) {
        try {
            CommonService.setCookie(AdminRouteComponent.TenantCookie, selectedTenant ? selectedTenant.guid : "", 30);
            this.setState({
                selectedTenant: selectedTenant
            }, this.getSites);

        }
        catch (error) {
            console.logError(error, "TenantSiteOfferCalculationQueryComponent", "onTenantClick");
        }
    }

    onSiteClick(id, selectedSite) {
        try {
            CommonService.setCookie(AdminRouteComponent.SiteCookie, selectedSite ? selectedSite.guid : "", 30);
            this.setState({
                selectedSite: selectedSite
            }, this.onGetClick);
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferCalculationQueryComponent", "onSiteClick");
        }
    }

    onGetClick(event) {
        try {
            this.props.onGetClick(this.state.selectedTenant, this.state.selectedSite);
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferCalculationQueryComponent", "onGetClick");
        }
    }

    onLookupClick(id, value) {
        try {
            this.setState({
                filterValue: value,
            });
            this.props.onLookupClick(value);
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferCalculationQueryComponent", "onLookupClick");
        }
    }

    onTextChange(id, value) {
        try {
            this.setState({
                [id]: value,
            });
            this.props.onLookupClick(value);
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferCalculationQueryComponent", "onTextChange");
        }
    }

    render() {
        return <div className="card">
            <div className="card-block">
                <div className="row">
                    {
                        this.props.isGlobalAdmin
                            ? <SellToWebAdminDropdownComponent label="Tenant" data={this.props.tenants} itemKey="name" onItemClick={this.onTenantClick} value={this.state.selectedTenant ? this.state.selectedTenant.name : "Select Tenant"} controlSpan="admin-tenant-control" />
                            : <Fragment>
                                <div className={"admin-control col-auto"}>
                                    <div className="input-group align-items-center">
                                        <label className={"admin-label col-form-label"}>
                                            Tenant:
                                        </label>
                                        {this.state.selectedTenant?.name}
                                    </div>
                                </div>
                            </Fragment>
                    }
                    <SellToWebAdminDropdownComponent label="Site" data={this.state.sites} itemKey="siteName" onItemClick={this.onSiteClick} value={this.state.selectedSite ? this.state.selectedSite.siteName : "Select Site"} />
                    <SellToWebAdminInputComponent id="filterValue" label="Search" value={this.state.filterValue} onChange={this.onTextChange} onLookupClick={this.onLookupClick} />

                    <div className="col-auto d-flex align-items-center">
                        Valuation : {this.state.selectedSite?.valuationMethod}
                    </div>
                    <div className="col-auto admin-query-btn">
                        <button className="btn btn-primary" onClick={this.onGetClick} disabled={!this.state.selectedSite}>
                            Get <span className="far fa-sync-alt admin-icon" />
                        </button>
                        <button className="btn btn-primary admin-icon" onClick={this.props.onAddClick}>
                            Add <span className="fas fa-plus admin-icon" />
                        </button>
                        {
                            this.state.selectedSite
                                ? <a className="btn btn-primary admin-icon" target="_blank" rel="noreferrer" href={"https://" + this.state.selectedSite.siteDomain}>
                                    View Site
                                </a>
                                : null
                        }
                    </div>
                    <div style={{ "height": "33px", "width": "auto" }}>
                        <SellToWebSpinnerComponent showSpinner={this.state.showSpinner || this.props.isLoadingData} />
                    </div>
                </div>
            </div>
        </div>;
    }
}
