import React, { Component } from "react";


export class SellToWebAdminRadioButtonComponent extends Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    onChange(event, item) {
        if (this.props.onChange) {
            this.props.onChange(this.props.id, item);
        }
    }

    onKeyDown(event) {
        if (this.props.onKeyDown) {
            this.props.onKeyDown(event, this.props.id, this.props.additionalData);
        }
    }

    render() {

        let colSpan = this.props.colSpan ? this.props.colSpan : "";
        let controlSpan = this.props.controlSpan ? this.props.controlSpan : "";
        let label = this.props.label ? this.props.label : null;
        let style = this.props.style ? this.props.style : null;
        if (this.props.cssName) {
            colSpan += " " + this.props.cssName;
        }
        if (this.props.controlCssName) {
            controlSpan += " " + this.props.controlCssName;
        }

        let rdlElements = [];
        if (this.props.data) {
            this.props.data.forEach((item, index) => {
                rdlElements.push(
                    <div key={"rdlItem" + index} className={controlSpan} style={{ "display": "inline-flex" }}>
                        <input type="radio" id={"rdl_" + this.props.name + "_" + index} name={this.props.name} className={"input-group " + this.props.className}
                            onChange={(event) => this.onChange(event, item)} checked={this.props.value === item ? true : false} disabled={item.disabled} />
                        <label htmlFor={"rdl_" + this.props.name + "_" + index}>
                            {
                                this.props.itemKey
                                    ? item[this.props.itemKey]
                                    : item
                            }
                        </label>
                    </div>
                )
            });
        }

        return <div className={"admin-control " + colSpan}>
            {
                label
                    ? <label className={"admin-label " + style}> {label}: </label>
                    : null
            }
            {rdlElements}
        </div>;
    }
}
