import React, { Component, Fragment } from "react";

import { CommonService } from "../js_modules/CommonService";

import { SellToWebTableColumnComponent } from "../common/selltowebtable/SellToWebTableColumnComponent";
import { SellToWebTableColumnCheckboxV2Component } from "../common/selltowebtable/SellToWebTableColumnCheckboxV2Component";
import { SellToWebTableRowComponent } from "../common/selltowebtable/SellToWebTableRowComponent";
import { SellToWebTableComponent } from "../common/selltowebtable/SellToWebTableComponent";
import { SellToWebModalConfirmationComponent } from "../common/selltowebmodalconfirmation/SellToWebModalConfirmationComponent";

import { TenantSiteOfferKBBCalculationEditComponent } from "./TenantSiteOfferKBBCalculationEditComponent";

export class TenantSiteOfferKBBCalculationComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            selectedRow: null,
            deleteRowData: null,
            showEditDialog: false
        };

        this.onSaveClick = this.onSaveClick.bind(this);
        this.onActionColumnCallback = this.onActionColumnCallback.bind(this);
        this.onActionEditClick = this.onActionEditClick.bind(this);
        this.onActionDeleteClick = this.onActionDeleteClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
        this.onCancelEditClick = this.onCancelEditClick.bind(this);
        this.onCancelDeleteClick = this.onCancelDeleteClick.bind(this);
        this.onConfirmDeleteClick = this.onConfirmDeleteClick.bind(this);
    }

    pageTitle = "Offer Calculation";

    componentDidMount() {
        try {
            CommonService.clientAzureStorageLog("TenantSiteOfferKBBCalculationComponent", "componentDidMount", null, null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferKBBCalculationComponent", "componentDidMount");
        }
    }

    onActionColumnCallback(rowData) {
        return <Fragment>
            <button type="button" className="btn btn-link" onClick={(event) => this.onActionEditClick(event, rowData)}>
                <i className="fas fa-edit" />
            </button>
            <button type="button" className="btn btn-link" onClick={(event) => this.onActionDeleteClick(event, rowData)} hidden={rowData.year == null && rowData.make == null && rowData.model == null}>
                <i className="fas fa-times text-danger" />
            </button>
        </Fragment>;
    }

    onActionEditClick(event, rowData) {
        try {
            this.setState({
                selectedRow: rowData,
                showEditDialog: true,
            });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferKBBCalculationComponent", "onActionEditClick");
        }
    }

    onActionDeleteClick(event, rowData) {
        try {
            this.setState({
                deleteRowData: rowData
            });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferKBBCalculationComponent", "onActionDeleteClick");
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
            CommonService.clientAzureStorageLog("TenantSiteOfferKBBCalculationComponent", "onCopyTableCallBack", null, null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferKBBCalculationComponent", "onCopyTableCallBack");
        }
    }

    onSaveClick() {
        if (this.props.addingNewRecord) {
            this.props.onCancelAddClick();
        }
        this.setState({
            showEditDialog: false,
            selectedRow: null
        }, this.props.onGetClick(this.props.selectedTenant, this.props.selectedSite));
    }

    onCancelEditClick() {
        if (this.props.addingNewRecord) {
            this.props.onCancelAddClick();
        }
        this.setState({
            showEditDialog: false,
            selectedRow: null
        });
    }

    onConfirmDeleteClick() {
        try {
            if (this.state.deleteRowData) {
                let inputData = {
                    siteGuid: this.state.deleteRowData.siteGuid,
                    year: this.state.deleteRowData.year ? CommonService.formatYear(this.state.deleteRowData.year.toString()) : null,
                    make: this.state.deleteRowData.make,
                    model: this.state.deleteRowData.model,
                    isDelete: true,
                    siteName: this.props.selectedSite.siteName,
                    tenantShortName: this.props.selectedTenant.shortName,
                };

                this.setState({
                    isLoadingData: true,
                    deleteRowData: null
                });

                this.props.applicationInsights.trackTrace({ message: "TenantSiteOfferKBBCalculationComponent/onConfirmDeleteClick", properties: { ...inputData, logon: this.props.logon } });
                fetch("/TenantSite/SaveSiteCalculationVariable", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json; charset=utf-8;"
                    },
                    body: JSON.stringify(inputData),
                    credentials: "same-origin"
                })
                    .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                    .then(parsedResponse => {
                        this.setState({
                            isLoadingData: false
                        }, () => {
                            this.props.onShowAlert("success", { message: "Record deleted successfully!" }, 4000);
                            this.props.onGetClick(this.props.selectedTenant, this.props.selectedSite);
                        });
                    })
                    .catch(notOKResponse => {
                        this.setState({
                            isLoadingData: false
                        });
                        if (notOKResponse.status === 500) {
                            notOKResponse.json()
                                .then(parsedError => {
                                    this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TenantSite/SaveSiteCalculationVariable", ...inputData, ...parsedError, logon: this.props.logon } });
                                    console.logError(parsedError, "TenantSiteOfferKBBCalculationComponent", "onConfirmDeleteClick");
                                    this.props.onShowAlert("danger", parsedError);
                                })
                                .catch(jsonParseError => {
                                    console.logError(jsonParseError, "TenantSiteOfferKBBCalculationComponent", "onConfirmDeleteClick");
                                });
                        }
                    });
            }

        }
        catch (error) {
            console.logError(error, "TenantSiteOfferKBBCalculationComponent", "onConfirmDeleteClick");
        }
    }

    onCancelDeleteClick() {
        try {
            this.setState({
                deleteRowData: null
            });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferKBBCalculationComponent", "onCancelDeleteClick");
        }
    }


    render() {
        return <Fragment>
            <SellToWebModalConfirmationComponent
                headerText={
                    "Delete Calculation Setup: " +
                    (this.state.deleteRowData
                        ? (this.state.deleteRowData.year ? this.state.deleteRowData.year : "") + " " +
                        (this.state.deleteRowData.make ? this.state.deleteRowData.make : "") + " " +
                        (this.state.deleteRowData.model ? this.state.deleteRowData.model : "")
                        : "")
                }
                bodyText="Are you sure?"
                showConfirmation={this.state.deleteRowData !== null}
                onOkClick={this.onConfirmDeleteClick}
                onCancelClick={this.onCancelDeleteClick}
                className="admin-modal-confirmation-dialog"
            />
            <TenantSiteOfferKBBCalculationEditComponent
                showDialog={this.props.addingNewRecord ? true : this.state.showEditDialog}
                isAddingRecord={this.props.addingNewRecord}
                rowData={this.props.addingNewRecord ? null : this.state.selectedRow}
                onSaveClick={this.onSaveClick}
                onCancelClick={this.onCancelEditClick}
                onShowAlert={this.props.onShowAlert}
                selectedTenant={this.props.selectedTenant}
                selectedSite={this.props.selectedSite}
                applicationInsights={this.props.applicationInsights}
                logon={this.props.logon}
            />

            <SellToWebTableComponent cardClassName="admin-flex-item" headerText={this.props.tableTitle} tableData={this.props.tableData} paintTable={this.props.paintTable}
                copyTableConfig={{ "columns": [0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15], onCopyTableCallBack: this.props.onCopyTableCallBack }} tablePageSize={CommonService.tablePageSize}
            >
                <SellToWebTableRowComponent>
                    <SellToWebTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                    <SellToWebTableColumnComponent headerText="Action" dataColumnCallback={this.onActionColumnCallback} dataColumn="showSpinner" showSpinner />
                    <SellToWebTableColumnComponent headerText="Make" dataColumn="make" sortColumn="make" filterColumn="make" className="text-center" />
                    <SellToWebTableColumnComponent headerText="Year" dataColumn="year" sortColumn="year" filterColumn="year" className="text-center" />
                    <SellToWebTableColumnComponent headerText="Model" dataColumn="model" sortColumn="model" filterColumn="model" className="text-center" />
                    <SellToWebTableColumnComponent headerText="Low Value Condition" dataColumn="lowValueCondition" sortColumn="lowValueCondition" filterColumn="lowValueCondition" className="text-center" />
                    <SellToWebTableColumnComponent headerText="High Value Condition" dataColumn="highValueCondition" sortColumn="highValueCondition" filterColumn="highValueCondition" className="text-center" />
                    <SellToWebTableColumnComponent headerText="Excellent Condition Factor (0-1)" dataColumn="excellentCondition" sortColumn="excellentCondition" filterColumn="excellentCondition" dataType="number_2" className="text-center" />
                    <SellToWebTableColumnComponent headerText="Very Good Condition Factor (0-1)" dataColumn="veryGoodCondition" sortColumn="veryGoodCondition" filterColumn="veryGoodCondition" dataType="number_2" className="text-center" />
                    <SellToWebTableColumnComponent headerText="Good Condition Factor (0-1)" dataColumn="goodCondition" sortColumn="goodCondition" filterColumn="goodCondition" dataType="number_2" className="text-center" />
                    <SellToWebTableColumnComponent headerText="Fair Condition Factor (0-1)" dataColumn="fairCondition" sortColumn="fairCondition" filterColumn="fairCondition" dataType="number_2" className="text-center" />
                    <SellToWebTableColumnComponent headerText="Accident Deduction" dataColumn="accidentDeductionPct" sortColumn="accidentDeductionPct" filterColumn="accidentDeductionPct" dataType="percent_1" className="text-center" />
                    <SellToWebTableColumnComponent headerText="Missing Key Deduction" dataColumn="missingKeyDeduction" sortColumn="missingKeyDeduction" filterColumn="missingKeyDeduction" dataType="percent_1" className="text-center" />
                    <SellToWebTableColumnComponent headerText="General Adjustment" dataColumn="generalAdjustmentPct" sortColumn="generalAdjustmentPct" filterColumn="generalAdjustmentPct" dataType="percent_1" className="text-center" />
                    <SellToWebTableColumnComponent headerText="Max Mileage Adj Deduction" dataColumn="maxMileageAdjustmentPct" sortColumn="maxMileageAdjustmentPct" filterColumn="maxMileageAdjustmentPct" dataType="percent_1" className="text-center" />
                    <SellToWebTableColumnCheckboxV2Component headerText="No Offer on Major Issues" dataColumn="noOfferOnMajorIssues" sortColumn="noOfferOnMajorIssues" labelColumn="noOfferMajorIssueLabel" label2Column="noOfferMajorIssueLabel2" dataType="bool" headerClassName="admin-no-offer-major-issue-column" />
                    <SellToWebTableColumnCheckboxV2Component headerText="Enable Offer Calculation" dataColumn="enableOfferCalculation" sortColumn="enableOfferCalculation" labelColumn="EnableOfferLabel" label2Column="EnableOfferLabel2" dataType="bool" headerClassName="admin-enable-offer-column" />
                    <SellToWebTableColumnComponent headerText="Updated Date" dataColumn="updatedDate" sortColumn="updatedDate" filterColumn="updatedDate" dataType="dateTime" className="text-center" />
                </SellToWebTableRowComponent>
            </SellToWebTableComponent>
        </Fragment>
    }
}