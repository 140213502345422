import React, { Component, Fragment } from "react";

import { CommonService } from "../js_modules/CommonService";

import { SellToWebTableColumnComponent } from "../common/selltowebtable/SellToWebTableColumnComponent";
import { SellToWebTableColumnCheckboxV2Component } from "../common/selltowebtable/SellToWebTableColumnCheckboxV2Component";
import { SellToWebTableRowComponent } from "../common/selltowebtable/SellToWebTableRowComponent";
import { SellToWebTableComponent } from "../common/selltowebtable/SellToWebTableComponent";
import { SellToWebModalConfirmationComponent } from "../common/selltowebmodalconfirmation/SellToWebModalConfirmationComponent";

import { TenantSiteOfferMMRCalculationEditComponent } from "./TenantSiteOfferMMRCalculationEditComponent";
import { SellToWebTableHeaderComponent } from "../common/selltowebtable/SellToWebTableHeaderComponent";
import { SellToWebAdminTextAreaComponent } from "../common/selltowebtextarea/SellToWebAdminTextAreaComponent";
import { SellToWebSpinnerComponent } from "../common/selltowebspinner/SellToWebSpinnerComponent";

export class TenantSiteOfferMMRCalculationComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            tenants: null,
            selectedRow: null,
            deleteRowData: null,
            showEditDialog: false,
            tableData: [],
            paintTable: false
        };

        this.onSaveClick = this.onSaveClick.bind(this);
        this.onActionColumnCallback = this.onActionColumnCallback.bind(this);
        this.onActionEditClick = this.onActionEditClick.bind(this);
        this.onActionDeleteClick = this.onActionDeleteClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
        this.onCancelEditClick = this.onCancelEditClick.bind(this);
        this.onCancelDeleteClick = this.onCancelDeleteClick.bind(this);
        this.onConfirmDeleteClick = this.onConfirmDeleteClick.bind(this);
        this.onAddCommentColumnCallback = this.onAddCommentColumnCallback.bind(this);
        this.onCommentTextChange = this.onCommentTextChange.bind(this);
        this.onSaveCommentClick = this.onSaveCommentClick.bind(this);
    }

    pageTitle = "Offer Calculation";

    componentDidMount() {
        try {
            CommonService.clientAzureStorageLog("TenantSiteOfferMMRCalculationComponent", "componentDidMount", null, null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferMMRCalculationComponent", "componentDidMount");
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.tableData !== null && this.state.tableData !== null && this.props.tableData !== prevProps.tableData) {
            this.setState({
                tableData: this.props.tableData,
                paintTable: this.props.paintTable
            })
        }

    }

    onActionColumnCallback(rowData) {
        try {
            return <Fragment>
                <button type="button" className="btn btn-link" onClick={(event) => this.onActionEditClick(event, rowData)}>
                    <i className="fas fa-edit" />
                </button>
                <button type="button" className="btn btn-link" onClick={(event) => this.onActionDeleteClick(event, rowData)} hidden={(rowData.year === null && rowData.make === null && rowData.model === null) || (rowData.year === "Default" && rowData.make === "Default" && rowData.model === "Default")}>
                    <i className="fas fa-times text-danger" />
                </button>
            </Fragment>;
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferMMRCalculationComponent", "onActionColumnCallback");
        }

    }

    onActionEditClick(event, rowData) {
        try {
            this.setState({
                selectedRow: rowData,
                showEditDialog: true
            });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferMMRCalculationComponent", "onActionEditClick");
        }
    }

    onActionDeleteClick(event, rowData) {
        try {
            this.setState({
                deleteRowData: rowData
            });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferMMRCalculationComponent", "onActionDeleteClick");
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
            CommonService.clientAzureStorageLog("TenantSiteOfferMMRCalculationComponent", "onCopyTableCallBack", null, null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferMMRCalculationComponent", "onCopyTableCallBack");
        }
    }

    onSaveClick() {
        try {
            if (this.props.addingNewRecord) {
                this.props.onCancelAddClick();
            }
            this.setState({
                showEditDialog: false,
                selectedRow: null
            }, this.props.onGetClick(this.props.selectedTenant, this.props.selectedSite));
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferMMRCalculationComponent", "onSaveClick");
        }
    }

    onCancelEditClick() {
        try {
            if (this.props.addingNewRecord) {
                this.props.onCancelAddClick();
            }
            this.setState({
                showEditDialog: false,
                selectedRow: null
            });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferMMRCalculationComponent", "onCancelEditClick");
        }
    }

    onConfirmDeleteClick() {
        try {
            if (this.state.deleteRowData) {
                let inputData = {
                    siteGuid: this.state.deleteRowData.siteGuid,
                    year: this.state.deleteRowData.year ? this.state.deleteRowData.year : null,
                    make: this.state.deleteRowData.make,
                    model: this.state.deleteRowData.model,
                    isDelete: true,
                    siteName: this.props.selectedSite.siteName,
                    tenantShortName: this.props.selectedTenant.shortName,
                };

                this.setState({
                    isLoadingData: true,
                    deleteRowData: null
                });

                this.props.applicationInsights.trackTrace({ message: "TenantSiteOfferMMRCalculationComponent/onConfirmDeleteClick", properties: { ...inputData, logon: this.props.logon } });
                fetch("/TenantSite/SaveSiteCalculationConfigMMR", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json; charset=utf-8;"
                    },
                    body: JSON.stringify(inputData),
                    credentials: "same-origin"
                })
                    .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                    .then(parsedResponse => {
                        this.setState({
                            isLoadingData: false
                        }, () => {
                            this.props.onShowAlert("success", { message: "Record deleted successfully!" }, 4000);
                            this.props.onGetClick(this.props.selectedTenant, this.props.selectedSite);
                        });
                    })
                    .catch(notOKResponse => {
                        this.setState({
                            isLoadingData: false
                        });
                        if (notOKResponse.status === 500) {
                            notOKResponse.json()
                                .then(parsedError => {
                                    this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TenantSite/SaveSiteCalculationVariable", ...inputData, ...parsedError, logon: this.props.logon } });
                                    console.logError(parsedError, "TenantSiteOfferMMRCalculationComponent", "onConfirmDeleteClick");
                                    this.props.onShowAlert("danger", parsedError);
                                })
                                .catch(jsonParseError => {
                                    console.logError(jsonParseError, "TenantSiteOfferMMRCalculationComponent", "onConfirmDeleteClick");
                                });
                        }
                    });
            }

        }
        catch (error) {
            console.logError(error, "TenantSiteOfferMMRCalculationComponent", "onConfirmDeleteClick");
        }
    }

    onCancelDeleteClick() {
        try {
            this.setState({
                deleteRowData: null
            });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferMMRCalculationComponent", "onCancelDeleteClick");
        }
    }

    onAddCommentColumnCallback(rowData) {
        try {
            return <Fragment>
                <div className="form-inline row">
                    <SellToWebAdminTextAreaComponent id={"comments_" + rowData.id} value={rowData.comments} rows="3" onChange={this.onCommentTextChange} colSpan="col-11" controlSpan="col-12" />
                    <button type="button" className="btn btn-link dgdg-font-size-18 col-1" onClick={(event) => this.onSaveCommentClick(event, rowData)}>
                        <span className="fas fa-save" />
                    </button>
                    <SellToWebSpinnerComponent showSpinner={rowData.comments_showSpinner} />
                </div>
            </Fragment>;
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferMMRCalculationComponent", "onAddCommentColumnCallback");
        }
    }
    onCommentTextChange(id, value) {
        try {
            let tableData = this.props.tableData;
            let rowData = tableData.filter(item => (item.id === parseInt(id.split('_')[1])))[0];
            rowData.comments = value;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferMMRCalculationComponent", "onCommentTextChange");
        }
    }

    onSaveCommentClick(event, rowData) {
        let tableData = this.state.tableData;
        try {
            rowData.comments_showSpinner = true;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
            let inputData = {
                id: rowData.id,
                comments: rowData.comments ? rowData.comments.replace(/\n|\r/g, " ") : rowData.comments,
            };
            fetch("/TenantSite/SaveSiteCalculationConfigMMRComments", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    rowData.original_comments = rowData.comments;
                    rowData.comments_showSpinner = false;
                    this.setState({
                        isLoadingData: false,
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    })
                    this.props.onShowAlert("success", { message: "Record saved successfully!" }, 4000);
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableData: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TenantSite/SaveSiteCalculationConfigMMRComments", ...inputData, ...parsedError, logon: this.props.logon } });
                                console.logError(parsedError, "TenantSiteOfferMMRCalculationComponent", "onSaveCommentClick");
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "TenantSiteOfferMMRCalculationComponent", "onSaveCommentClick");
                            });
                    }
                })
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferMMRCalculationComponent", "onSaveCommentClick");
        }
    }

    render() {
        return (
            <div>
                <SellToWebModalConfirmationComponent
                    headerText={
                        "Delete Calculation Setup: " +
                        (this.state.deleteRowData
                            ? (this.state.deleteRowData.year ? this.state.deleteRowData.year : "") + " " +
                            (this.state.deleteRowData.make ? this.state.deleteRowData.make : "") + " " +
                            (this.state.deleteRowData.model ? this.state.deleteRowData.model : "")
                            : "")
                    }
                    bodyText="Are you sure?"
                    showConfirmation={this.state.deleteRowData !== null}
                    onOkClick={this.onConfirmDeleteClick}
                    onCancelClick={this.onCancelDeleteClick}
                    className="admin-modal-confirmation-dialog"
                />
                <TenantSiteOfferMMRCalculationEditComponent
                    showDialog={this.props.addingNewRecord ? true : this.state.showEditDialog}
                    isAddingRecord={this.props.addingNewRecord}
                    rowData={this.props.addingNewRecord ? null : this.state.selectedRow}
                    onSaveClick={this.onSaveClick}
                    onCancelClick={this.onCancelEditClick}
                    onShowAlert={this.props.onShowAlert}
                    selectedTenant={this.props.selectedTenant}
                    selectedSite={this.props.selectedSite}
                    applicationInsights={this.props.applicationInsights}
                    logon={this.state.logon}
                    defaultRow={this.props.tableData !== null ? this.props.tableData.filter(row => row.isDefault) : ""}
                />

                <SellToWebTableComponent cardClassName="admin-flex-item" headerText={this.props.tableTitle} tableData={this.state.tableData} paintTable={this.state.paintTable}
                    copyTableConfig={{ "columns": [0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15], onCopyTableCallBack: this.props.onCopyTableCallBack }} tablePageSize={CommonService.tablePageSize}
                >
                    <SellToWebTableHeaderComponent position="PreHeader">
                        <tr className="admin-table-header">
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td colSpan="4" className="text-center font-weight-bold admin-table-sub-header">Condition Grade</td>
                            <td colSpan="3" className="text-center font-weight-bold admin-table-sub-header">Tire Adjustment</td>
                            <td colSpan="3" className="text-center font-weight-bold admin-table-sub-header">Windshield Adjustment</td>
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                        </tr>
                    </SellToWebTableHeaderComponent>
                    <SellToWebTableRowComponent hasHeader>
                        <SellToWebTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                        <SellToWebTableColumnComponent headerText="Action" dataColumnCallback={this.onActionColumnCallback} dataColumn="showSpinner" showSpinner />
                        <SellToWebTableColumnComponent headerText="Make" dataColumn="make" sortColumn="make" filterColumn="make" className="text-center" />
                        <SellToWebTableColumnComponent headerText="Year" dataColumn="year" sortColumn="year" filterColumn="year" className="text-center" />
                        <SellToWebTableColumnComponent headerText="Model" dataColumn="model" sortColumn="model" filterColumn="model" className="text-center" />
                        <SellToWebTableColumnComponent headerText="Maximum Offer" dataColumn="maximumOfferAmount" sortColumn="maximumOfferAmount" filterColumn="maximumOfferAmount" dataType="money" className="text-center" />
                        <SellToWebTableColumnComponent headerText="Maximum Odometer" dataColumn="maximumOdometer" sortColumn="maximumOdometer" filterColumn="maximumOdometer" dataType="formatted_number" className="text-center" />
                        <SellToWebTableColumnComponent headerText="Minimum Year" dataColumn="minumumYear" sortColumn="minumumYear" filterColumn="minumumYear" dataType="number" className="text-center" />
                        <SellToWebTableColumnComponent headerText="Minimum Average Grade " dataColumn="minimumAverageGrade" sortColumn="minimumAverageGrade" filterColumn="minimumAverageGrade" dataType="number_2" className="text-center" />
                        <SellToWebTableColumnComponent headerText="Like New" dataColumn="gradeLikeNewConditionValue" sortColumn="gradeLikeNewConditionValue" filterColumn="gradeLikeNewConditionValue" dataType="number_2" className="text-center" />
                        <SellToWebTableColumnComponent headerText="Very Good" dataColumn="gradeVeryGoodConditionValue" sortColumn="gradeVeryGoodConditionValue" filterColumn="gradeVeryGoodConditionValue" dataType="number_2" className="text-center" />
                        <SellToWebTableColumnComponent headerText="Acceptable" dataColumn="gradeAcceptableConditionValue" sortColumn="gradeAcceptableConditionValue" filterColumn="gradeAcceptableConditionValue" dataType="number_2" className="text-center" />
                        <SellToWebTableColumnComponent headerText="Major Issues" dataColumn="gradeMajorIssuesConditionValue" sortColumn="gradeMajorIssuesConditionValue" filterColumn="gradeMajorIssuesConditionValue" dataType="number_2" className="text-center" />
                        <SellToWebTableColumnComponent headerText="Tire Adjustment Threshold​" dataColumn="tireAdjustmentThreshold" sortColumn="tireAdjustmentThreshold" filterColumn="tireAdjustmentThreshold" dataType="money" className="text-center" />
                        <SellToWebTableColumnComponent headerText="Below Tire Adjustment Threshold" dataColumn="tireAdjustmentBelowThresholdAmount" sortColumn="tireAdjustmentBelowThresholdAmount" filterColumn="tireAdjustmentBelowThresholdAmount" dataType="money" className="text-center" headerTextClassName="admin-table-header-text" />
                        <SellToWebTableColumnComponent headerText="Above Tire Adjustment Threshold" dataColumn="tireAdjustmentAboveThresholdAmount" sortColumn="tireAdjustmentAboveThresholdAmount" filterColumn="tireAdjustmentAboveThresholdAmount" dataType="money" className="text-center" headerTextClassName="admin-table-header-text" />
                        <SellToWebTableColumnComponent headerText="Windshield Adjustment Threshold" dataColumn="windshieldAdjustmentThreshold" sortColumn="windshieldAdjustmentThreshold" filterColumn="windshieldAdjustmentThreshold" dataType="money" className="text-center" />
                        <SellToWebTableColumnComponent headerText="Below Windshield Adjustment Threshold" dataColumn="windshieldAdjustmentBelowThresholdAmount" sortColumn="windshieldAdjustmentBelowThresholdAmount" filterColumn="windshieldAdjustmentBelowThresholdAmount" dataType="money" className="text-center" headerTextClassName="admin-table-header-text" />
                        <SellToWebTableColumnComponent headerText="Above Windshield Adjustment Threshold" dataColumn="windshieldAdjustmentAboveThresholdAmount" sortColumn="windshieldAdjustmentAboveThresholdAmount" filterColumn="windshieldAdjustmentAboveThresholdAmount" dataType="money" className="text-center" headerTextClassName="admin-table-header-text" />
                        <SellToWebTableColumnComponent headerText="General Adjustment" dataColumn="generalAdjustmentValue" sortColumn="generalAdjustmentValue" filterColumn="generalAdjustmentValue" className="text-center" />
                        <SellToWebTableColumnComponent headerText="Accident Deduction" dataColumn="accidentAdjustmentValue" sortColumn="accidentAdjustmentValue" filterColumn="accidentAdjustmentValue" className="text-center" />
                        <SellToWebTableColumnCheckboxV2Component headerText="Enable Offer Calculation" dataColumn="enableOfferCalculation" sortColumn="enableOfferCalculation" labelColumn="EnableOfferLabel" label2Column="EnableOfferLabel2" dataType="bool" headerClassName="admin-enable-offer-column" />
                        <SellToWebTableColumnComponent headerText="Updated Date" dataColumn="updatedDate" sortColumn="updatedDate" filterColumn="updatedDate" dataType="dateTime" className="text-center" />
                        <SellToWebTableColumnComponent headerText="Updated By" dataColumn="updatedBy" sortColumn="updatedBy" filterColumn="updatedDate" className="text-center" />

                    </SellToWebTableRowComponent>
                    <SellToWebTableRowComponent>
                        <SellToWebTableColumnComponent dataText="" colSpan="2" />
                        <SellToWebTableColumnComponent colSpan="6" dataColumnCallback={this.onAddCommentColumnCallback} />
                    </SellToWebTableRowComponent>
                </SellToWebTableComponent>
            </div >
        );
    }
}